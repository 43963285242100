.DocumentFeature {
    /* background-color: pink; */
    margin-bottom: 15px;
    border: 1px solid rgb(26, 56, 96, 0.2);
    border-radius: 10px;
    /* padding: 10px; */
    box-shadow: 0px 3px 5px rgb(26, 56, 96, 0.2);
}

.DocumentFeature .documentFeatureContainer {
    padding: 10px;
    border-bottom: 1.5px solid rgb(26, 56, 96, 0.15);
}

.DocumentFeature .documentFeatureContainer .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
}

.DocumentFeature .documentFeatureContainer .top .section1 {
    font-size: 16px;
    display: flex;
    align-items: center;
}

.DocumentFeature .documentFeatureContainer .top .section1 .section1Ref {
    padding: 3px 10px;
    color: #FFFFFF;
    font-weight: 600;
    border-radius: 7.5px;
    background-color: #FF7C45;
}

.DocumentFeature .documentFeatureContainer .top .section1 .section1RefNum {
    font-weight: 600;
    color: #192434;
    padding-left: 10px;
}

.DocumentFeature .documentFeatureContainer .top .documentActions {
    display: flex;
    align-items: center;
    white-space: nowrap;
    font-size: 13px;
    /* background-color: pink; */
}

.DocumentFeature .documentFeatureContainer .top .documentActions .createImageAnnotation {
    margin-right: 10px;
}

.DocumentFeature .documentFeatureContainer .top .documentActions .createImageAnnotation .createImageAnnotationButton {
    background-color: #F4F7FA;
    color: #0A7637;
    font-weight: 500;
    border-radius: 7.5px;
    padding: 5px 10px;
}

.DocumentFeature .documentFeatureContainer .top .documentActions .createImageAnnotation .createImageAnnotationButton:hover {
    background-color: #E8F0F5;
}

.DocumentFeature .documentFeatureContainer .top .documentActions .addReferenceNoteButton {
    background-color: #FFFFFF;
    width: fit-content;
    color: #192434;
    font-weight: 500;
    border-radius: 7.5px;
    padding: 5px 10px;
    margin-right: 10px;
}

.DocumentFeature .documentFeatureContainer .top .documentActions .addReferenceNoteButton:hover {
    background-color: rgb(26, 94, 155, 0.1);
}

.DocumentFeature .documentFeatureContainer .top .documentActions .referenceNotes .viewReferenceNoteButton {
    background-color: rgba(26, 56, 96, 0.1);
    width: fit-content;
    color: #192434;
    font-weight: 500;
    border-radius: 7.5px;
    padding: 5px 10px;
    margin-right: 10px;
}

.DocumentFeature .documentFeatureContainer .top .documentActions .referenceNotes .viewReferenceNoteButton:hover {
    background-color: rgba(26, 56, 96, 0.3);
}

.DocumentFeature .documentFeatureContainer .top .documentActions .referenceNotes .notesDropDown.show {
    width: 30vw;
    height: 40vh;
    border-radius: 10px;
    padding: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    background-color: #FFFFFF;
    border: 1px solid rgb(26, 56, 96, 0.2);
}

.DocumentFeature .documentFeatureContainer .top .documentActions .referenceNotes .notesDropDown .noteHeader {
    padding: 2.5px 10px;
    border-bottom: 1px solid rgb(26, 56, 96, 0.1);
    font-weight: 600;
    width: 100%;
}

.DocumentFeature .documentFeatureContainer .top .documentActions .referenceNotes .notesDropDown .noteContainer {
    padding: 10px;
    width: 100%;
    height: 100%;
}

.DocumentFeature .documentFeatureContainer .top .documentActions .referenceNotes .notesDropDown .noteContainer .noteBody {
    border: 1px solid rgb(28, 48, 74, 0.15);
    border-radius: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    background-color: rgb(25, 59, 103, 0.05);
}

.DocumentFeature .documentFeatureContainer .top .documentActions .referenceNotes .notesDropDown .noteContainer .noteBody textarea {
    width: 100%;
    background-color: rgb(25, 59, 103, 0);
    border: none;
    resize: none;
    height: 100%;
    margin: 0px 0px 10px 0px;
    border: none;
    font-size: 13px;
}

.DocumentFeature .documentFeatureContainer .top .documentActions .referenceNotes .notesDropDown .noteContainer .noteBody textarea:focus {
    outline: none;
}

.DocumentFeature .documentFeatureContainer .top .documentActions .referenceNotes .notesDropDown .noteContainer .noteBody .lastUpdatedBy {
    font-size: 11px;
    text-align: right;
    font-style: italic;
    color: #1A5E9B;
    /* padding-left: 10px; */
}

.DocumentFeature .documentFeatureContainer .top .documentActions .referenceNotes .notesDropDown .noteActionButtons {
    display: flex;
    justify-content: center;
    padding: 5px 10px;
}

.DocumentFeature .documentFeatureContainer .top .documentActions .referenceNotes .notesDropDown .noteActionButtons .noteDeleteButton {
    background-color: rgb(202, 21, 12, 0.8);
    color: #FFFFFF;
    font-weight: 500;
    font-size: 13px;
    border-radius: 7.5px;
    padding: 5px 10px;
}

.DocumentFeature .documentFeatureContainer .top .documentActions .referenceNotes .notesDropDown .noteActionButtons .noteDeleteButton:hover {
    background-color: rgb(202, 21, 12, 1);
}

.DocumentFeature .documentFeatureContainer .top .documentActions .referenceNotes .notesDropDown .noteActionButtons .noteSaveButton {
    background-color: rgb(21, 132, 68, 1);
    color: #FFFFFF;
    font-weight: 500;
    font-size: 13px;
    border-radius: 7.5px;
    padding: 5px 10px;
    margin-right: 10px;
}

.DocumentFeature .documentFeatureContainer .top .documentActions .referenceNotes .notesDropDown .noteActionButtons .noteSaveButton:hover {
    border: 0.5px solid rgb(21, 132, 68, 0.5);
}

.DocumentFeature .documentFeatureContainer .top .documentActions .referenceDelete .referenceDeleteButton {
    background-color: #FCE8E7;
    color: #192434;
    font-weight: 500;
    border-radius: 7.5px;
    padding: 5px 10px;
}

.DocumentFeature .documentFeatureContainer .top .documentActions .referenceDelete .referenceDeleteButton:hover {
    border: 1px solid rgb(226, 29, 18, 0.5);
}

.DocumentFeature .documentFeatureContainer .documentData {
    font-weight: 500;
}

.DocumentFeature .documentFeatureContainer .documentData .label {
    color: rgb(28, 48, 74, 0.65);
    margin-right: 5px;
}

.DocumentFeature .documentFeatureContainer .documentData .documentHead .documentTitle {
    font-size: 15px;
}

.DocumentFeature .documentFeatureContainer .documentData .documentHead .documentNumber a {
    text-decoration: none;
}

.DocumentFeature .documentFeatureContainer .documentData .documentHead .documentNumber a:hover {
    text-decoration: underline;
}

.DocumentFeature .documentFeatures {
    padding: 20px 10px 10px 10px;
}

.DocumentFeature .modal-dialog {
    padding-top: 40px;
    width: 100%;
}

.DocumentFeature .modal-content {
    border-radius: 10px;
    border: none;
    width: 100%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
}

.DocumentFeature .modal-header {
    border: none;
    padding: 10px;
    text-align: center;
    flex-direction: column;
    /* border-bottom: 2px solid rgb(26, 56, 96, 0.1); */
}

.DocumentFeature .modal-header .modal-title {
    font-size: 20px;
    font-weight: 600;
    color: #192434;
    padding-top: 5px;
}

.DocumentFeature .modal-header .modal-title .refNumber {
    padding: 5px 10px;
    color: #FFFFFF;
    font-weight: 600;
    font-size: 16px;
    border-radius: 7.5px;
    background-color: #FF7C45;
}

.DocumentFeature .modal-header .modalRefTitle {
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    padding: 10px 0px;
    color: rgb(27, 43, 65, 0.7);
    text-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.DocumentFeature .modal-body {
    padding: 10px;
    text-align: left;
}

.DocumentFeature .modal-body .warningMessage {
    font-weight: 600;
    padding: 10px;
    background-color: #FCE8E7;
    border-radius: 7.5px;
}

.DocumentFeature .modal-footer {
    display: flex;
    justify-content: center;
    border: none;
    padding-top: 0px;
    flex-wrap: nowrap;
}

.DocumentFeature .modal-footer button {
    width: 100%; 
}

.DocumentFeature .modal-footer button.cancel {
    background-color: #FFFFFF;
    color: #192434;
    font-weight: 600;
    border: 1px solid rgb(28, 55, 90, 0.15);
    border-radius: 7.5px;
}

.DocumentFeature .modal-footer button.cancel:hover {
    background-color: rgb(26, 56, 96, 0.08);
}

.DocumentFeature .modal-footer button.createImageAnnotationSubmit {
    background-color: rgb(21, 132, 68, 0.9);
    color: #FFFFFF;
    font-weight: 600;
    border-radius: 7.5px;
}

.DocumentFeature .modal-footer button.createImageAnnotationSubmit:hover {
    background-color: rgb(21, 132, 68, 1);
}

.DocumentFeature .modal-footer button.submit {
    background-color: rgb(226, 29, 18, 0.9);
    color: #FFFFFF;
    font-weight: 600;
    border-radius: 7.5px;
}

.DocumentFeature .modal-footer button.submit:hover {
    background-color: rgb(226, 29, 18, 1);
}

.DocumentFeature .loading {
    height: 100%;
}

.DocumentFeature .loading .spinner-border {
    width: 3rem;
    height: 3rem;
}