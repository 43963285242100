.Pdf {
    width: 100%;
    height: 100%;
    display: flex;
}

.Pdf .close-button {
    text-align: right;
    position: absolute;
    right: 6px;
    top: 2px;
    cursor: pointer;
}

.Pdf iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 4px solid #192434;
    border-radius: 10px 10px 0px 0px;
}