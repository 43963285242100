.HomeProjectsItem {
    background-color: rgb(255, 255, 255, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 300px;
    height: 100px;
    padding: 5px;
    border-radius: 10px;
    border: 1px solid rgb(26, 56, 96, 0.1);
    cursor: pointer;
}

.HomeProjectsItem:hover {
    background-color: rgb(255, 255, 255, 1);
    border: 1px solid #1A5E9B;
}

.HomeProjectsItem .section1 {
    display: flex;
    /* flex-direction: column; */
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: fit-content;
    border-radius: 7.5px;
    padding: 5px;
    font-size: 12px;
    color: #1A5E9B;
    background-color: #F0F1F4;
}

.HomeProjectsItem .section1 .section1a {
    display: flex;
    align-items: center;
    /* background-color: red; */
}

.HomeProjectsItem .section1 .section1a .projectNumber {
    text-decoration: underline;
}

.HomeProjectsItem .section1 .section1b {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    border-radius: 7.5px;
    font-weight: 600;
    font-size: 11px;
    background-color: #FFFFFF;
}

.HomeProjectsItem .section2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 10px;
    font-weight: 500;
    color: rgb(25, 36, 52, 0.9);
    overflow: hidden;
    text-overflow: ellipsis;
}

.HomeProjectsItem .section3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: fit-content;
    font-size: 11px;
}

.HomeProjectsItem .section3 .company {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.HomeProjectsItem .section3 .status {
    white-space: nowrap;
    padding: 0px 5px;
    border-radius: 5px;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.HomeProjectsItem .section3 .status.inProgress {
    color: #DFB300;
    border: 1.5px solid #DFB300;
}

.HomeProjectsItem .section3 .status.inReview {
    color: rgb(0, 150, 255);
    border: 1.5px solid rgb(0, 150, 255);
}

.HomeProjectsItem .section3 .status.completed {
    color: rgb(80, 200, 120);
    border: 1.5px solid rgb(80, 200, 120);
}

.HomeProjectsItem .section3 .status .statusColor {
    width: 7.5px;
    height: 7.5px;
    border-radius: 50%;
    margin-right: 2.5px;
}

.HomeProjectsItem .section3 .status .statusColor.inProgress {
    background-color: #DFB300;
    box-shadow: 0 0 5px #DFB300;
}

.HomeProjectsItem .section3 .status .statusColor.inReview {
    background-color: rgb(0, 150, 255);
    box-shadow: 0 0 5px rgb(0, 150, 255);
}

.HomeProjectsItem .section3 .status .statusColor.completed {
    background-color: rgb(80, 200, 120);
    box-shadow: 0 0 5px rgb(80, 200, 120);
}

.HomeProjectsItem .section3 .statusText {
    display: inline-block;
    font-weight: bold;
    white-space: nowrap;
}