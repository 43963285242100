.DocumentFeatureItem {
    border: 1px solid rgb(26, 56, 96, 0.1);
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
    background-color: rgb(232, 234, 236, 0.2);
}

.DocumentFeatureItem .topContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.DocumentFeatureItem .topContent .elementNum {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 13px;
}

.DocumentFeatureItem .topContent .elementNum .elementNumContainer {
    background-color: #FFFFFF;
    box-shadow: 0px 0px 7.5px rgb(26, 56, 96, 0.2);
    padding: 5px;
    border-radius: 17.5px;
}

.DocumentFeatureItem .topContent .elementNum .elementNumContainer .item1 {
    padding: 3px 6.5px;
    border-radius: 50%;
    color: #1A5E9B;
    background-color: #DDE9FA;
}

.DocumentFeatureItem .topContent .imageRotation {
    cursor: pointer;
    padding: 3px;
    border-radius: 7.5px;
}

.DocumentFeatureItem .topContent .imageRotation:hover {
    background-color: rgba(26, 56, 96, 0.1);
}

.DocumentFeatureItem .topContent .imageRotation:active {
    background-color: rgba(26, 56, 96, 0.2);
}

.DocumentFeatureItem .topContent .imageRotation.disabled {
    cursor: wait;
    background-color: rgba(26, 56, 96, 0.2);
}

.DocumentFeatureItem .annotation .annotationContainer {
    display: flex;
    justify-content: space-between;
    max-height: 600px;
}

.DocumentFeatureItem .annotation .annotationContainer .element {
    max-height: 100%;
    width: 100%;
    overflow: auto;
}

.DocumentFeatureItem .annotation .annotationContainer img {
    max-width: 60%;
    max-height: 100%;
    object-fit: contain;
}

.DocumentFeatureItem .element {
    width: 100%;
}

.DocumentFeatureItem .annotationDeleteContainer {
    display: flex;
    align-items: flex-end;
}

.DocumentFeatureItem .annotationDeleteContainer .annotationDeleteButton {
    background-color: #FCE8E7;
    border: 1.5px solid rgb(226, 29, 18, 0.4);
    color: #192434;
    font-weight: 500;
    border-radius: 7.5px;
    padding: 5px;
}

.DocumentFeatureItem .annotationDeleteContainer .annotationDeleteButton:hover {
    background-color: rgb(226, 29, 18, 0.1);
}

.DocumentFeatureItem .annotationDeleteContainer .modal-dialog {
    padding-top: 40px;
    width: 100%;
}

.DocumentFeatureItem .annotationDeleteContainer .modal-content {
    border-radius: 10px;
    border: none;
    width: 100%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
}

.DocumentFeatureItem .annotationDeleteContainer .modal-header {
    border: none;
    /* padding: 10px; */
    flex-direction: column;
    
}

.DocumentFeatureItem .annotationDeleteContainer .modal-header .modal-title {
    width: 100%;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
}

.DocumentFeatureItem .annotationDeleteContainer .modal-header .modal-title {
    color: #CA150C;
    padding: 0px;
}

.DocumentFeatureItem .annotationDeleteContainer .modal-body {
    border-bottom: 2px solid rgb(26, 56, 96, 0.1);
}

.DocumentFeatureItem .annotationDeleteContainer .modal-body .modalAnnotationText {
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    padding: 10px 5px;
    color: rgb(27, 43, 65, 1);
    text-align: left;
    background-color: rgba(26, 56, 96, 0.1);
}

.DocumentFeatureItem .annotationDeleteContainer .modal-body .modalAnnotationImage {
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    padding: 10px 5px;
    color: rgb(27, 43, 65, 1);
    text-align: center;
    background-color: rgba(26, 56, 96, 0.1);
}

.DocumentFeatureItem .annotationDeleteContainer .modal-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    padding-top: 10px;
    flex-wrap: nowrap;
}

.DocumentFeatureItem .annotationDeleteContainer .modal-footer button {
    width: 100%; 
}

.DocumentFeatureItem .annotationDeleteContainer .modal-footer button.cancel {
    background-color: #FFFFFF;
    color: #192434;
    font-weight: 600;
    border: 1px solid rgb(28, 55, 90, 0.15);
    border-radius: 7.5px;
}

.DocumentFeatureItem .annotationDeleteContainer .modal-footer button.cancel:hover {
    background-color: rgb(26, 56, 96, 0.08);
}

.DocumentFeatureItem .annotationDeleteContainer .modal-footer button.submit {
    background-color: rgb(226, 29, 18, 0.9);
    color: #FFFFFF;
    font-weight: 600;
    border-radius: 7.5px;
}

.DocumentFeatureItem .annotationDeleteContainer .modal-footer button.submit:hover {
    background-color: rgb(226, 29, 18, 1);
}