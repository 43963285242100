.UserProfile {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow: auto;
}

.UserProfile .cover {
    height: fit-content;
    width: 100%;
}

.UserProfile .cover svg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background: linear-gradient(150deg, #1A5E9B, #192434);
}

.UserProfile .mainSection {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 10px 50px 20px 50px;
}

.UserProfile .mainSection .topSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: fit-content;
}

.UserProfile .mainSection .topSection .summary {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}

.UserProfile .mainSection .topSection .summary .avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #FFFFFF;
    border: 3.5px solid white;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    position: absolute;
    top: -40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.UserProfile .mainSection .topSection .summary .avatar img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.UserProfile .mainSection .topSection .summary .info {
    display: flex;
    flex-direction: column;
    margin-left: 120px;
}

.UserProfile .mainSection .topSection .summary .info .name {
    font-size: 22px;
    font-weight: 600;
    padding: 0px;
}

.UserProfile .mainSection .topSection .summary .info .email {
    font-size: 15px;
    padding: 0px;
    color: rgb(28, 48, 74, 0.65);
}

.UserProfile .mainSection .topSection .navigation {
    display: flex;
    /* flex-wrap: nowrap; */
    width: fit-content;
    background-color: #FFFFFF;
    font-size: 14px;
    font-weight: 500;
    border-radius: 5px;
    border: 1px solid #D0D5DD;
    overflow: hidden;
    white-space: nowrap;
}

.UserProfile .mainSection .topSection .navigation button {
    color: black;
    width: fit-content;
    padding: 5px 10px;
    border-radius: 0px;
    border-left: 1px solid #D0D5DD;
}

.UserProfile .mainSection .topSection .navigation button:first-child {
    border-left: none;
}

.UserProfile .mainSection .topSection .navigation .nav-link.active {
    background-color: #F4F7FA;
    color: #1A5E9B;
}

@media screen and (max-width: 730px) {
    .UserProfile .mainSection .topSection .summary {
        flex-direction: column;
        align-items: center;
    }

    .UserProfile .mainSection .topSection .summary .avatar {
        position: relative;
        top: 0px;
        /* margin-bottom: 20px; */
    }

    .UserProfile .mainSection .topSection .summary .info {
        margin-left: 0px;
        text-align: center;
    }

    .UserProfile .mainSection .topSection .summary .info .name {
        font-size: 18px;
    }
    
    .UserProfile .mainSection .topSection .summary .info .email {
        font-size: 13px;
    }

    .UserProfile .mainSection .topSection .navigation {
        margin-top: 20px;
        font-size: 12px;
    }

    .UserProfile .mainSection .topSection .summary .avatar {
        width: 70px;
        height: 70px;
    }
}

.UserProfile .mainSection .bottomSection {
    width: 100%;
    display: flex;
    /* height: 100%;
    max-height: fit-content; */
    margin-top: 30px;
    background-color: #FFFFFF;
    border-radius: 10px;
    /* background-color: purple; */

}

.UserProfile .mainSection .bottomSection .content {
    width: 100%;
    height: 100%;
}

.UserProfile .mainSection .bottomSection .content .accountInfo {
    width: 100%;
    height: 100%;
    display: none;
}

.UserProfile .mainSection .bottomSection .content .accountInfo.show {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.UserProfile .mainSection .bottomSection .content .accountInfo.show .header {
    width: 100%;
    font-size: 20px;
    font-weight: 600;
    padding: 10px 20px;
    border-bottom: 1px solid #D0D5DD;
}

.UserProfile .mainSection .bottomSection .content .accountInfo.show .userProfileContentBody {
    width: 100%;
    height: 100%;
    padding: 0px 20px;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.UserProfile .mainSection .bottomSection .content .accountInfo.show .userProfileContentBody .title1 {
    font-size: 17px;
    font-weight: 600;
    width: 25%;
}

.UserProfile .mainSection .bottomSection .content .accountInfo.show .userProfileContentBody .title2 {
    font-size: 14px;
    font-weight: 500;
    color: rgb(25, 36, 52, 0.94);
}

.UserProfile .mainSection .bottomSection .content .accountInfo.show .userProfileContentBody .section {
    display: flex;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 5px;
}

.UserProfile .mainSection .bottomSection .content .accountInfo.show .userProfileContentBody .content {
    border: 1px solid #D0D5DD;
    padding: 5px 10px;
    font-size: 14px;
    border-radius: 7.5px;
}



.UserProfile .mainSection .bottomSection .content .accountInfo.show .userProfileContentBody .names {
    display: flex;
    justify-content: space-between;
    padding: 20px 0px;
    border-bottom: 0.5px solid #D0D5DD;
}

.UserProfile .mainSection .bottomSection .content .accountInfo.show .userProfileContentBody .names .namesContent {
    display: flex;
    flex-direction: column;
    width: 75%;
}

.UserProfile .mainSection .bottomSection .content .accountInfo.show .userProfileContentBody .email {
    display: flex;
    justify-content: space-between;
    padding: 20px 0px;
    border-bottom: 0.5px solid #D0D5DD;
}

.UserProfile .mainSection .bottomSection .content .accountInfo.show .userProfileContentBody .email .emailContent {
    display: flex;
    flex-direction: column;
    width: 75%;
    
}

.UserProfile .mainSection .bottomSection .content .accountInfo.show .userProfileContentBody .company {
    display: flex;
    justify-content: space-between;
    padding: 20px 0px;
}

.UserProfile .mainSection .bottomSection .content .accountInfo.show .userProfileContentBody .company .companyContent {
    display: flex;
    flex-direction: column;
    width: 75%;
}

.UserProfile .mainSection .bottomSection .content .accountInfo.show .userProfileContentBody .photoUpdate {
    display: flex;
    justify-content: space-between;
    padding: 20px 0px;
}

.UserProfile .mainSection .bottomSection .content .updatePassword {
    width: 100%;
    height: 100%;
    display: none;
}

.UserProfile .mainSection .bottomSection .content .updatePassword.show {
    width: 100%;
    height: 100%;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
}

.UserProfile .mainSection .bottomSection .content .updatePassword.show .header {
    width: 100%;
    font-size: 20px;
    font-weight: 600;
    padding: 10px 20px;
    border-bottom: 1px solid #D0D5DD;
}

.UserProfile .mainSection .bottomSection .content .updatePassword.show .userProfileContentBody {
    width: 100%;
    height: 100%;
    padding: 10px 20px;
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.UserProfile .mainSection .bottomSection .content .help {
    width: 100%;
    height: 100%;
    display: none;
}

.UserProfile .mainSection .bottomSection .content .help.show {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.UserProfile .mainSection .bottomSection .content .help.show .header {
    width: 100%;
    font-size: 20px;
    font-weight: 600;
    padding: 10px 20px;
    border-bottom: 1px solid #D0D5DD;
}

.UserProfile .mainSection .bottomSection .content .help.show .userProfileContentBody {
    width: 100%;
    height: 100%;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    overflow: auto;
}