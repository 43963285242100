.ProjectAnalysis {
    padding: 20px 60px;
    display: flex;
    flex-direction: column;
    overflow: auto;
    font-size: 14px;
}

.ProjectAnalysis .projectHeader {
    font-weight: 600;
    font-size: 22px;
    display: flex;
    align-items: center;
    padding: 20px 0px;
}

.ProjectAnalysis .projectHeader .projectHeaderImage {
    margin-right: 10px;
}

.ProjectAnalysis .toggleRefCombinationsContainer {
    width: 100%;
    padding: 10px 0px;
    display: flex;
    justify-content: center;
}

.ProjectAnalysis .toggleRefCombinationsContainer .toggleRefCombinations {
    width: fit-content;
    padding: 5px 40px;
    border-radius: 20px;
    color: #1A5E9B;
    background-color: #FFFFFF;
    box-shadow: 0 0 5px rgb(26, 94, 155, 0.4);
    font-weight: 600;
    cursor: pointer;
}

.ProjectAnalysis .toggleRefCombinationsContainer .toggleRefCombinations:hover {
    background-color: rgb(26, 94, 155, 0.2);
}