.CreateUserForm.modal-dialog {
    min-width: 70%;
}

.CreateUserForm .formContainer .header {
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgb(26, 56, 96, 0.1);
    padding: 5px 10px;
}

.CreateUserForm .formContainer .header .left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.CreateUserForm .formContainer .header .left .second .sec1 {
    font-size: 15px;
    font-weight: 600;
    text-align: left;
}

.CreateUserForm .formContainer .header .left .second .sec2 {
    font-size: 11px;
    font-weight: 400;
    text-align: left;
}

.CreateUserForm .formContainer .header .right svg {
    cursor: pointer;
}

.CreateUserForm .formContainer .header .right svg:hover {
    border: 1px solid #000000;
    border-radius: 50%;
}

.CreateUserForm .formContainer form {
    font-size: 14px;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.CreateUserForm .formContainer form .formContent {
    overflow: auto;
    padding: 10px;
    text-align: left;
    font-weight: 600;
}

.CreateUserForm .formContainer form .formContent input, 
.CreateUserForm .formContainer form .formContent select,
.CreateUserForm .formContainer form .formContent textarea {
    font-size: 13px;
    border: 1px solid rgb(28, 48, 74, 0.15);
}

.CreateUserForm .formContainer form .formContent .groupContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
}

.CreateUserForm .formContainer form .formContent .groupContainer .firstHalf {
    width: 50%;
    padding-right: 5px;
}

.CreateUserForm .formContainer form .formContent .groupContainer .secondHalf {
    width: 50%;
    padding-left: 5px;
}

.CreateUserForm .formContainer form .formContent .groupContainer small {
    font-size: 11px;
    font-weight: 400;
    color: rgb(28, 48, 74, 0.5);

}

.CreateUserForm .formContainer form .formContent .checkIsValidUser {
    width: 100%;
    border-radius: 5px;
    padding: 2px 5px;
}

.CreateUserForm .formContainer form .formContent .checkIsAvailableUser {
    width: 100%;
}

.CreateUserForm .formContainer form .formContent .checkIsValidEmail {
    width: 100%;
    border-radius: 5px;
    padding: 2px 5px;
}

.CreateUserForm .formContainer form .formContent .checkIsAvailableEmail {
    width: 100%;
}

.CreateUserForm .formContainer .formButtonContainer {
    height: 10%;
    padding: 2.5px 10px;
    height: fit-content;
    border-top: 1px solid rgb(26, 56, 96, 0.1);
    /* background-color: orange; */
}

.CreateUserForm .formContainer .formButtonContainer button {
    height: fit-content;
    width: 100%;
    padding: 10px;
    margin: 10px;
    color: #FFFFFF;
    font-weight: 500;
    font-size: 14px;
}

.CreateUserForm .formContainer .formButtonContainer button.cancel {
    color: black;
    border: 1px solid rgb(28, 48, 74, 0.15);
    background-color: #ffffff;
}

.CreateUserForm .formContainer .formButtonContainer button.cancel:hover {
    background-color: #f6f7f9;
}

.CreateUserForm .formContainer .formButtonContainer button.submit:hover {
    border: 0.5px solid #1A5E9B;
}
