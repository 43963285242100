.Help {
    height:100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding: 30px 40px 20px 40px;
}

.Help .title {
    margin-bottom: 10px;
}

.Help .title .header {
    font-size: 25px;
    font-weight: 700;
}

.Help .helpSection {
    background-color: #FFFFFF;
    padding: 10px 40px;
    border-radius: 7.5px;
}