.PriorArtsReference {
    width: 100%;
    margin-top: 40px;
}

.PriorArtsReference .header {
    font-weight: 700;
    font-size: 20px;
    color: #192434;
}

.PriorArtsReference .tableContainer {
    width: 100%;
    overflow: auto;
    margin-top: 10px;
}

.PriorArtsReference .table {
    width: 100%;
    border-radius: 10px;
    border: 1px solid rgba(26, 56, 96, 0.1);
    border-spacing: 0;
    border-collapse: separate;
    overflow: hidden;
}

.PriorArtsReference .table th:not(:last-child),
.PriorArtsReference .table td:not(:last-child) {
    border-right: 1px solid rgba(26, 56, 96, 0.1);
}

.PriorArtsReference .table td {
    border: none;
}

.PriorArtsReference .table>thead>tr:not(:last-child)>th,
.PriorArtsReference .table>thead>tr:not(:last-child)>td,
.PriorArtsReference .table>tbody>tr:not(:last-child)>th,
.PriorArtsReference .table>tbody>tr:not(:last-child)>td,
.PriorArtsReference .table>tfoot>tr:not(:last-child)>th,
.PriorArtsReference .table>tfoot>tr:not(:last-child)>td,
.PriorArtsReference .table>tr:not(:last-child)>td,
.PriorArtsReference .table>tr:not(:last-child)>th,
.PriorArtsReference .table>thead:not(:last-child),
.PriorArtsReference .table>tbody:not(:last-child),
.PriorArtsReference .table>tfoot:not(:last-child) {
    border-bottom: 1px solid rgba(26, 56, 96, 0.1);
}



.PriorArtsReference .table thead {
    color: rgb(25, 59, 103, 0.65);
    font-size: 16px;
    text-align: center;
}

.PriorArtsReference .serialNumber {
    width: fit-content;
    white-space: nowrap;
}

.PriorArtsReference .documentNumber {
    width: fit-content;
    white-space: nowrap;
}

.PriorArtsReference .publicationDate {
    width: fit-content;
    white-space: nowrap;
}

.PriorArtsReference .element {
    width: fit-content;
    white-space: nowrap;
    vertical-align: middle;
}

.PriorArtsReference .priorArtsReferenceDocument {
    vertical-align: middle;
}

.PriorArtsReference .priorArtsReferenceDocument:hover {
    background-color: #F8F9FA;
    cursor: grab;
}

.PriorArtsReference .priorArtsReferenceDocument .refNum {
    text-align: center;
    color: #1A5E9B;
    font-weight: 600;
    width: fit-content;
}

.PriorArtsReference .priorArtsReferenceDocument .documentNumber {
    white-space: nowrap;
    max-width: 400px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: rgb(25, 36, 52, 0.9);
    font-weight: 600;
    text-decoration: none;
    padding-left: 10px;
}

.PriorArtsReference .priorArtsReferenceDocument .documentNumber a:hover {
    text-decoration: underline;
}

.PriorArtsReference .priorArtsReferenceDocument .docPubDate {
    white-space: nowrap;
    font-weight: 500;
    text-align: center;
}

.PriorArtsReference .priorArtsReferenceDocument .docPubDate span {
    width: fit-content;
    padding: 5px 10px;
    background-color: #F8F9FA;
    border-radius: 7.5px;
    border: 1px solid rgb(26, 94, 155, 0.08);
}