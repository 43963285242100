.NonPatentData {
    position: relative !important;
    top: 50px !important;
    z-index: 1 !important;
}

.NonPatentData div {
    position: relative !important;
    display: block !important;
}

.NonPatentData div [hidden] {
    display: block !important;
}


.NonPatentData a, .NonPatentData button, .NonPatentData select, .NonPatentData input, .NonPatentData textarea {
    position: relative !important;
    pointer-events: none;
}

.NonPatentData .annotationHighlight {
    background-color: rgba(255, 255, 0, 0.6);
}