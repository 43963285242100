.AnnotateFeatureButtonsItem {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.AnnotateFeatureButtonsItem:hover {
    background-color: #F6F7F9;
    border-radius: 5px;
}

.AnnotateFeatureButtonsItem .annotateFeatureButtonsItemDropdown .annotateFeatureButtonsItemDropdownToggle {
    text-align: center;
    color: #192434;
    /* background-color: red; */
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: space-between;
    font-size: 11px;
    word-wrap:break-word;
    padding: 3px 5px;
}

.AnnotateFeatureButtonsItem .annotateFeatureButtonsItemDropdown .annotateFeatureButtonsItemDropdownToggle.show {
    background-color: #E1E1E1;
    border-radius: 5px;
}

.AnnotateFeatureButtonsItem .annotateFeatureButtonsItemDropdown .annotateFeatureButtonsItemDropdownToggle .feature {
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: blueviolet; */
}

.AnnotateFeatureButtonsItem .annotateFeatureButtonsItemDropdown .annotateFeatureButtonsItemDropdownToggle .feature .featureNum {
    width: 20px;
    height: 20px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(26, 94, 155, 0.15);
    color: #1A5E9B;
    font-weight: 600;
    margin-right: 3px;
}

.AnnotateFeatureButtonsItem .annotateFeatureButtonsItemDropdown .annotateFeatureButtonsItemDropdownToggle .feature .element {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    color: #192434;
}

.AnnotateFeatureButtonsItem .dropdown-menu.annotateFeatureButtonsItemDropdown {
    min-width: 20vw;
    /* max-height: 50vh; */
    min-height: 5vh;
    overflow-y: visible;
    text-align: center;
    border-radius: 3px;
    border: 1px solid rgb(26, 56, 96, 0.1);
    font-size: 13px;
    box-shadow: 0px 0px 2px 2px #F6F7F9;
}

.AnnotateFeatureButtonsItem .dropdown-menu.annotateFeatureButtonsItemDropdown .annotateFeatureButtonsItemDropdownContainer {
    height: 100%;
}

.AnnotateFeatureButtonsItem .dropdown-menu.annotateFeatureButtonsItemDropdown .annotateFeatureButtonsItemDropdownContainer .previewSelectedText {
    height: 10vh;
    overflow-y: auto;
    margin: 10px;
    padding: 2px;
    border: 2px solid gray;
    border-radius: 5px;
    text-align: left;
}

.AnnotateFeatureButtonsItem .dropdown-menu.annotateFeatureButtonsItemDropdown .annotateFeatureButtonsItemDropdownContainer .previewFeatureText {
    overflow-y: auto;
    margin: 10px;
    padding: 2px;
    background-color: rgba(211, 211, 211, 0.4);
    font-size: 12px;
    text-align: left;
}

.AnnotateFeatureButtonsItem .dropdown-menu.annotateFeatureButtonsItemDropdown .annotateFeatureButtonsItemDropdownContainer .featureTextPreview {
    cursor: pointer;
    font-weight: 700;
}

.AnnotateFeatureButtonsItem .dropdown-menu.annotateFeatureButtonsItemDropdown .annotateFeatureButtonsItemDropdownContainer .featureTextPreview:hover {
    font-weight: 800;
    text-decoration: underline;
}

.AnnotateFeatureButtonsItem .dropdown-menu.annotateFeatureButtonsItemDropdown .annotateFeatureButtonsItemDropdownContainer .annotationTextForm {
    margin: 10px;
    text-align: left;
    display: flex;
    flex-direction: column;
}

.AnnotateFeatureButtonsItem .dropdown-menu.annotateFeatureButtonsItemDropdown .annotateFeatureButtonsItemDropdownContainer h6{
    font-weight: bold;
    font-style: italic;
    font-size: 13px;
}

.AnnotateFeatureButtonsItem .dropdown-menu.annotateFeatureButtonsItemDropdown .annotateFeatureButtonsItemDropdownContainer .annotationTextForm .formFields {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.AnnotateFeatureButtonsItem .dropdown-menu.annotateFeatureButtonsItemDropdown .annotateFeatureButtonsItemDropdownContainer .annotationTextForm .formFields input {
    font-size: 13px;
}

.AnnotateFeatureButtonsItem .dropdown-menu.annotateFeatureButtonsItemDropdown .annotateFeatureButtonsItemDropdownContainer .annotationTextForm .formFields select {
    font-size: 13px;
}

.AnnotateFeatureButtonsItem .dropdown-menu.annotateFeatureButtonsItemDropdown .annotateFeatureButtonsItemDropdownContainer .annotationTextForm .annotationSubmissionButton {
    background-color: #158444;
    font-size: 14px;
    font-weight: 600;
    padding: 5px;
}

.AnnotateFeatureButtonsItem .dropdown-menu.annotateFeatureButtonsItemDropdown .annotateFeatureButtonsItemDropdownContainer .annotationTextForm .annotationSubmissionButton:hover {
    background-color: #0A7637;
}

.AnnotateFeatureButtonsItem .dropdown-menu.annotateFeatureButtonsItemDropdown .annotateFeatureButtonsItemDropdownContainer .annotationTextForm .annotationSubmissionButton:active {
    border: 1px solid #0A7637;
}