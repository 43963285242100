.Login {
    height: 100vh;
    /* background-color: purple; */
    /* color: white; */
    display: flex;
}

.Login .leftSide {
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 40%;
    padding: 20px;
}

.Login .rightSide {
    background: #192434;
    width: 60%;
    margin: 0px;
    overflow-x: auto;
    position: relative;
}

.Login .rightSide img {
    /* background-color: purple; */
    height: 100%;
    /* width: 100%; */
    position: absolute;
    bottom: 0;
    right: 0;
}

@media screen and (max-width: 850px) {
    .Login .leftSide {
        width: 30%;
    }
    .Login .rightSide {
        width: 70%;
    }
    
}

@media screen and (max-width: 925px) {
    .Login .rightSide img {
        /* height: 100%; */
        width: 100%;
    }
    
}

@media screen and (max-height: 872px) {
    .Login .leftSide {
        width: 30%;
    }
    .Login .rightSide {
        width: 70%;
    }
}

/* @media screen and (max-width: 480px){
    .Login .rightSide img {
        height: 100%;
        width: 100%;
    }
} */

