body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.headingText {
  color: #192434;
}

.bodyText {
  color: #192434;
}

.primaryText {
  color: #104B81;
}

.secondaryText {
  color: #1B2B41;
}

.tertiaryText {
  color: #8993A1;
}

.disabledText {
  color: #C4CAD3;
}

.errorText {
  color: #CA150C;
}

.warningText {
  color: #DFB300;
}

.successText {
  color: #0A7637;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.loading .spinner-border {
  width: 6rem;
  height: 6rem;
  font-size: 1.5rem;
  color: #1A5E9B;
}

.primary {
  background-color: #1A5E9B;
}

.primary-50 {
  background-color: #1A5E9B;
  opacity: 0.5;
}

.primary-25 {
  background-color: #1A5E9B;
  opacity: 0.25;
}

.primary-10 {
  background-color: #1A5E9B;
  opacity: 0.1;
}

.error {
  background-color: #E21D12;
}

.error-50 {
  background-color: #E21D12;
  opacity: 0.5;
}

.error-25 {
  background-color: #E21D12;
  opacity: 0.25;
}

.error-10 {
  background-color: #E21D12;
  opacity: 0.1;
}

.warning {
  background-color: #FFCC00;
}

.warning-50 {
  background-color: #FFCC00;
  opacity: 0.5;
}

.warning-25 {
  background-color: #FFCC00;
  opacity: 0.25;
}

.warning-10 {
  background-color: #FFCC00;
  opacity: 0.1;
}

.success {
  background-color: #158444;
}

.success-50 {
  background-color: #158444;
  opacity: 0.5;
}

.success-25 {
  background-color: #158444;
  opacity: 0.25;
}

.success-10 {
  background-color: #158444;
  opacity: 0.1;
}

button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border: none;
  padding: 10px 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}

button:disabled {
  opacity: 0.8;
  cursor: not-allowed;
}

.buttonPrimary {
  background-color: #1A5E9B;
}

.buttonPrimary:hover {
  background-color: #104B81;
}

.buttonError {
  background-color: #E21D12;
}

.buttonError:hover {
  background-color: #CA150C;
}

.buttonWarning {
  background-color: #FFCC00;
}

.buttonWarning:hover {
  background-color: #DFB300;
}

.buttonSuccess {
  background-color: #158444;
}

.buttonSuccess:hover {
  background-color: #0A7637;
}

.logoImage {
  width: 30px;
}

.tooltipContainer {
  position: relative;
}

.tooltipContainer .tooltiptext {
  visibility: hidden;
  width: 120px;
  top: 110%;
  left: 20%;
  background-color: #8993A1;
  /* background-color: rgb(26, 94, 155, 0.7); */
  color: #192434;
  color: #FFFFFF;
  text-align: center;
  border-radius: 5px;
  padding: 2.5px 5px;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.2;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltipContainer:hover .tooltiptext {
/* .tooltipContainer .tooltiptext { */
  visibility: visible;
}

.tooltipContainer .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;  /* At the top of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #8993A1 transparent;
}