.ProjectAnalysisSummary {
    background-color: #FFFFFF;
    border-radius: 10px;
    padding: 15px;
    border: 1px solid rgba(26, 56, 96, 0.1);
}

.ProjectAnalysisSummary .summary {
    padding: 10px;
    border-radius: 10px;
    color: #1A5E9B;
    background-color: #EDF2F7;
    border: 1px solid rgba(26, 56, 96, 0.1);
}

.ProjectAnalysisSummary .summary .title {
    font-size: 20px;
    font-weight: 600;
}

.ProjectAnalysisSummary .summary .summaryText {
    display: flex;
    flex-direction: column;
    padding: 10px 0px;
    color: rgb(28, 48, 74, 0.7);
    font-weight: 500;
}

.ProjectAnalysisSummary .summary .summaryText .mainMessage {
    display: flex;
    align-items: center;
}

.ProjectAnalysisSummary .summary .summaryText .listMark1 {
    width: 17.5px;
    height: 17.5px;
    background-color: #1A5E9B;
    border: 2px solid #FFFFFF;
    border-radius: 5px;
    margin-right: 10px;
}

.ProjectAnalysisSummary .summary .summaryText .listMark2 {
    width: 17.5px;
    height: 17.5px;
    background-color: rgb(26, 94, 155, 0.9);
    border: 2px solid #FFFFFF;
    border-radius: 5px;
    margin-right: 10px;
}

.ProjectAnalysisSummary .summary .summaryText .listMark3 {
    width: 17.5px;
    height: 17.5px;
    background-color: rgb(26, 94, 155, 0.8);
    border: 2px solid #FFFFFF;
    border-radius: 5px;
    margin-right: 10px;
}

.ProjectAnalysisSummary .summary .summaryText .summaryText1 {
    padding-bottom: 10px;
}

.ProjectAnalysisSummary .summary .summaryText .summaryRefContainer {
    display: flex;
    padding-left: 30px;
    padding-top: 5px;
    flex-wrap: wrap;
}

.ProjectAnalysisSummary .summary .summaryText .summaryRefContainer .summaryRef {
    width: fit-content;
    display: flex;
    align-items: center;
    padding: 4px;
    background-color: #FFFFFF;
    color: #192434;
    border: 1px solid rgba(26, 56, 96, 0.1);
    font-weight: 600;
    border-radius: 5px;
    margin-right: 15px;
    margin-bottom: 5px;
    white-space: nowrap;
    box-shadow: 0 0 10px rgb(26, 94, 155, 0.2);
}

.ProjectAnalysisSummary .summary .summaryText .summaryRefContainer span.singleRef {
    background-color: rgb(255, 124, 69, 0.15);
    color: #FF7C45;
    padding: 2px 7.5px;
    border-radius: 5px;
    margin-right: 5px;
}

.ProjectAnalysisSummary .summary .summaryText .summaryRefContainer span.multiRef {
    background-color: rgb(255, 124, 69, 0.15);
    color: #FF7C45;
    padding: 2px 7.5px;
    border-radius: 5px;
    margin-right: 5px;
}

.ProjectAnalysisSummary .summary .summaryText .summaryRefContainer span.joiner {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #EDF2F7;
    color: #1A5E9B;
    border: 2px solid #FFFFFF;
    box-shadow: 0 0 5px rgb(26, 94, 155, 0.4);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin: 0px 10px;
}

.ProjectAnalysisSummary .tableContainer {
    position: relative;
    width: 100%;
    margin-top: 20px;
    height: 100%;
    overflow: auto;
}
.ProjectAnalysisSummary .tableContainer table {
    width: 100%;
    font-size: 14px;
    border-radius: 10px;
    border: 1px solid rgb(232, 235, 239, 0.4);
    border-spacing: 0;
    border-collapse: separate;
    overflow: hidden;
    vertical-align: middle;
}

.ProjectAnalysisSummary .tableContainer .table th:not(:last-child),
.ProjectAnalysisSummary .tableContainer .table td:not(:last-child) {
    border-right: 1px solid rgb(232, 235, 239, 0.4);
}

.ProjectAnalysisSummary .tableContainer .table td {
    border: none;
}

.ProjectAnalysisSummary .tableContainer .table>thead>tr:not(:last-child)>th,
.ProjectAnalysisSummary .tableContainer .table>thead>tr:not(:last-child)>td,
.ProjectAnalysisSummary .tableContainer .table>tbody>tr:not(:last-child)>th,
.ProjectAnalysisSummary .tableContainer .table>tbody>tr:not(:last-child)>td,
.ProjectAnalysisSummary .tableContainer .table>tfoot>tr:not(:last-child)>th,
.ProjectAnalysisSummary .tableContainer .table>tfoot>tr:not(:last-child)>td,
.ProjectAnalysisSummary .tableContainer .table>tr:not(:last-child)>td,
.ProjectAnalysisSummary .tableContainer .table>tr:not(:last-child)>th,
.ProjectAnalysisSummary .tableContainer .table>thead:not(:last-child),
.ProjectAnalysisSummary .tableContainer .table>tbody:not(:last-child),
.ProjectAnalysisSummary .tableContainer .table>tfoot:not(:last-child) {
    border-bottom: 1px solid rgb(232, 235, 239, 0.4);
}

.ProjectAnalysisSummary .tableContainer .table thead {
    color: #FFFFFF;
    font-size: 16px;
    text-align: center;
    background-color: rgb(25, 36, 52, 0.9);
    position: -webkit-sticky;
    position: sticky;
    top: -2px;
}

.ProjectAnalysisSummary .tableContainer .table thead .reference {
    text-align: left;
}

.ProjectAnalysisSummary .tableContainer .table thead .element {
    white-space: nowrap;
}

.ProjectAnalysisSummary .tableContainer .table .tableBody {
    overflow: auto;
}

.ProjectAnalysisSummary .tableContainer .table tbody .reference {
    white-space: nowrap;
    display: flex;
    /* justify-content: center; */
    /* width: fit-content; */
}

.ProjectAnalysisSummary .tableContainer .table tbody .reference .referenceContainer {
    background-color: #F8F9FA;
    padding: 5px 7.5px;
    border-radius: 5px;
    box-shadow: 0 0 5px rgb(26, 94, 155, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
}


.ProjectAnalysisSummary .tableContainer .table .reference .referenceContainer span.multiRef {
    color: #FFFFFF;
    background-color: #FF7C45;
    padding: 2px 7.5px;
    border-radius: 5px;
    margin-right: 5px;
}

.ProjectAnalysisSummary .tableContainer .table .reference .referenceContainer span.joiner {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #EDF2F7;
    color: #1A5E9B;
    border: 2px solid #FFFFFF;
    box-shadow: 0 0 17.5px rgb(26, 94, 155, 0.5);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin: 0px 10px;
}

.ProjectAnalysisSummary .tableContainer td.element {
    padding: 5px;
    width: 50px;
    height: 50px;
    white-space: nowrap;
    text-align: center;
}

.ProjectAnalysisSummary .tableContainer td.element .projectAnalysisElementAbsent {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background-color: rgb(21, 132, 68, 0.7);
}

.ProjectAnalysisSummary .tableContainer td.element .projectAnalysisElementAbsent span {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 2px solid #FFFFFF;
    background-color: rgb(21, 132, 68, 1);
}

.ProjectAnalysisSummary .tableContainer td.element .projectAnalysisElementPresent {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background-color: rgb(226, 29, 18, 0.7);
}

.ProjectAnalysisSummary .tableContainer td.element .projectAnalysisElementPresent span {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 2px solid #FFFFFF;
    background-color: rgb(226, 29, 18, 1);
}

.ProjectAnalysisSummary .tableContainer .commentText {
   font-size: 13px;
    text-align: left;
    padding-left: 20px;
    white-space: nowrap;
    overflow: auto;
}