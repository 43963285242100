.ProjectCommentsItem {
    display: flex;
    width: 100%;
    border-bottom: 1.5px solid rgb(26, 56, 96, 0.15);
    padding: 7.5px 0px;
}

.ProjectCommentsItem:first-child {
    border-bottom: none;
}

.ProjectCommentsItem .avatar {
    padding-right: 10px;
}

.ProjectCommentsItem .commentDetails {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.ProjectCommentsItem .commentDetails .person {
    font-size: 14px;
    font-weight: 600;
}

.ProjectCommentsItem .commentDetails .commentText {
    font-size: 13px;
    padding: 5px 0px;
}

.ProjectCommentsItem .commentDetails .commentDate {
    font-size: 12px;
}