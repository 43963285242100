.ManualTextAnnotation {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.ManualTextAnnotation::-webkit-scrollbar {
    width: 5px;
}

.ManualTextAnnotation::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.4);
}

.ManualTextAnnotation .mainHeader {
    /* background-color: pink; */
    height: 60px;
    display: flex;
    align-items: center;
    padding: 0px 10px;
    overflow: auto;
}

.ManualTextAnnotation .mainHeader .sectionText {
    width: 70%;
    height: fit-content;
    overflow: auto;
}

@media screen and (max-width: 768px) {
    .ManualTextAnnotation .mainHeader .sectionText {
        width: 60%;
    }
    
}

.ManualTextAnnotation .mainHeader .sectionText .first {
    font-size: 15px;
    font-weight: 600;
}

.ManualTextAnnotation .mainHeader .sectionText .second {
    font-size: 12px;
}

.ManualTextAnnotation .aboutDocument {
    width: 100%;
    height: fit-content;
    border-top: 1px solid rgb(26, 56, 96, 0.15);
    padding: 10px;
    background-color: rgb(237, 242, 247, 0.8);
}

.ManualTextAnnotation .aboutDocument .section1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 30px;
    font-size: 13px;
}

.ManualTextAnnotation .aboutDocument .section1 .section1a {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow: auto;
}

.ManualTextAnnotation .aboutDocument .section1 .section1a .documentNumber {
    color: rgb(25, 36, 52, 0.9);
    font-weight: 600;
}

.ManualTextAnnotation .aboutDocument .section1 .section1b {
    display: flex;
    align-items: center;
    padding: 2.5px 10px;
    border: 1px solid rgb(26, 56, 96, 0.15);
    border-radius: 7.5px;
    background-color: #FFFFFF;
}

.ManualTextAnnotation .aboutDocument .section1 .section1b .date {
    font-weight: 600;
}

.ManualTextAnnotation .aboutDocument .section2 {
    font-size: 13px;
    font-weight: 500;
    overflow: auto;
}

.ManualTextAnnotation .aboutDocument .section3,
.ManualTextAnnotation .aboutDocument .section4 {
    font-size: 13px;
}

.ManualTextAnnotation .ManualTextAnnotationForm {
    width: 100%;
    height: 100%;
    border-top: 1px solid rgb(26, 56, 96, 0.15);
    padding: 15px;
    font-size: 13px;
    overflow: auto;
}

.ManualTextAnnotation .ManualTextAnnotationForm .projectElement {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    /* align-items: center; */
    margin-top: 25px;
}

.ManualTextAnnotation .ManualTextAnnotationForm .projectElement .projectElementNum {
    display: flex;
    background-color: rgb(25, 36, 52, 0.9);
    padding: 5px 10px 5px 7.5px;
    border-radius: 15px;
    position: absolute;
    left: 10px;
    top: -15px;
}

.ManualTextAnnotation .ManualTextAnnotationForm .projectElement .projectElementNum .num {
    background-color: #FFFFFF;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #1A5E9B;
    font-weight: 600;
}

.ManualTextAnnotation .ManualTextAnnotationForm .projectElement .projectElementNum .text {
    color: #FFFFFF;
    font-weight: 600;
}

.ManualTextAnnotation .ManualTextAnnotationForm .projectElement .projectElementText {
    border-radius: 10px;
    width: 100%;
    background-color: rgb(232, 234, 236, 0.8);
    text-align: left;
    /* background-color: blue; */
    padding-top: 14px;
    border: 1px solid rgb(26, 56, 96, 0.1);
}

.ManualTextAnnotation .ManualTextAnnotationForm .projectElement .projectElementText .elementText {
    padding: 0px 10px 5px 10px;
}

.ManualTextAnnotation .ManualTextAnnotationForm .projectElement .projectElementText .elementAnnotation {
    width: 100%;
    background-color: greenyellow;
    border-radius: 10px;
    /* height: 100%;
    padding: 5px;
    border: none;
    background-color: transparent;
    font-size: 13px;
    font-weight: 500;
    resize: none; */
}

.ManualTextAnnotation .ManualTextAnnotationForm .projectElement .projectElementText .elementAnnotation textarea {
    width: 100%;
    height: 100%;
    padding: 5px;
    border: none;
    background-color: #FFFFFF;
    font-size: 13px;
    border-radius: 10px;
    /* font-weight: 500; */
    resize: none;
}

.ManualTextAnnotation .ManualTextAnnotationForm .manualAnnotationSubmissionButton {
    background-color: #158444;
    font-size: 15px;
    font-weight: 600;
    padding: 5px;
}

.ManualTextAnnotation .ManualTextAnnotationForm .manualAnnotationSubmissionButton:hover {
    background-color: #0A7637;
}

.ManualTextAnnotation .ManualTextAnnotationForm .manualAnnotationSubmissionButton:active {
    border: 1px solid #0A7637;
}