.ProjectComments {
    width: 100%;
}

.ProjectComments .commentsHeading {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 20px;
}

.ProjectComments .commentsHeading .commentCount {
    color: #1A5E9B;
    background-color: rgb(26, 94, 155, 0.1);
    font-size: 14px;
    padding: 2px 7.5px;
    border-radius: 7.5px;
    border: 1px solid rgb(27, 43, 65, 0.13);
}

.ProjectComments .commentsBody {
   background-color: rgb(232, 234, 236, 0.33);
   margin-top: 10px;
   padding: 15px;
   border: 1px solid rgb(26, 56, 96, 0.1);
   border-radius: 10px;
}

.ProjectComments .commentsBody .comments {
    width: 100%;
    max-height: 300px;
    padding: 0px;
    white-space: break-spaces;
    overflow-y: auto;
    display: flex;
    flex-direction: column-reverse;
}

.ProjectComments .commentsBody .comment {
    display: flex;
    width: 100%;
    border-bottom: 1.5px solid rgb(26, 56, 96, 0.15);
    padding: 7.5px 0px;
}

.ProjectComments .commentsBody .comment:first-child {
    border-bottom: none;
}

.ProjectComments .commentsBody .comment .avatar {
    padding-right: 10px;
}

.ProjectComments .commentsBody .commentFormContainer {
    width: 100%;
    padding-top: 15px;
}

.ProjectComments .commentsBody .commentFormContainer form.commentForm {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 7.5px;
    background-color: #FFFFFF;
    border: 1px solid #1A5E9B;
    border-radius: 10px;
}

.ProjectComments .commentsBody .commentFormContainer form.commentForm textarea {
    margin: 0px 10px;
    border: none;
    border-radius: 10px;
    resize: none;
    font-size: 13px;
}

.ProjectComments .commentsBody .commentFormContainer form.commentForm button.addComment {
    background-color: #1A5E9B;
    color: #FFFFFF;
    font-weight: 500;
    font-size: 13px;
    border-radius: 7.5px;
    padding: 7.5px 20px;
    width: fit-content;
    white-space: nowrap;
    height: 100%;
}