td.PriorArtsReferenceDocumentElement {
    padding: 5px;
    width: 50px;
    height: 50px;
    white-space: nowrap;
    text-align: center;
}

td.PriorArtsReferenceDocumentElement .priorArtsReferenceDocumentElementAbsent {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background-color: rgb(21, 132, 68, 0.7);
}

td.PriorArtsReferenceDocumentElement .priorArtsReferenceDocumentElementAbsent span {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 2px solid #FFFFFF;
    background-color: rgb(21, 132, 68, 1);
}

td.PriorArtsReferenceDocumentElement .priorArtsReferenceDocumentElementPresent {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background-color: rgb(226, 29, 18, 0.7);
}

td.PriorArtsReferenceDocumentElement .priorArtsReferenceDocumentElementPresent span {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 2px solid #FFFFFF;
    background-color: rgb(226, 29, 18, 1);
}