.ImageThumbnailItem img {
    border: 1px solid gray;
    margin: 1px;
    cursor: pointer;
    max-width: 100px;
}

.ImageThumbnailItem img.present {
    border: 2px solid yellow;
    margin: 1px;
    cursor: pointer;
    max-width: 100px;
}