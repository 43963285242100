.ProjectSearchDocuments {
    /* margin: 0px 10px; */
    width: 100%;
    overflow: auto;
    border-radius: 5px;
    cursor: pointer;
}

.ProjectSearchDocuments hr {
    margin: 0px 10px;
    color: rgb(26, 56, 96, 0.2);
}

.ProjectSearchDocuments:hover {
    background-color: rgb(28, 55, 90, 0.2);
    border-radius: 5px;
}

.ProjectSearchDocuments .documentNumber {
    font-size: 13px;
    font-weight: 500;
    padding: 5px;
    display: flex;
    align-items: center;
    white-space: wrap;
}

.ProjectSearchDocuments .projectSearchDocumentNumber:hover {
    cursor: pointer;
    text-decoration: underline;
    color: blue;
}

.ProjectSearchDocuments .documentElements {
    margin-left: 30px;
    padding-bottom: 10px;
    font-size: 15px;
}

.ProjectSearchDocuments.highlightDocumentNumber {
    background-color: rgb(28, 55, 90, 0.3);
    border-radius: 5px;
}

