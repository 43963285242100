.ProjectsItem {
    height: 70px;
    font-size: 13px;
    border-bottom: 1px solid rgb(26, 56, 96, 0.1);
}

.ProjectsItem:hover {
    background-color: rgb(25, 59, 103, 0.08);
}

.ProjectsItem .ProjectsItemLink {
    color: #1A5E9B;
    font-weight: bold;
    /* padding-left: 15px; */
}

.ProjectsItem td {
    padding: 0px 10px;
    font-weight: 500;
}

.ProjectsItem .projectNumber {
    padding-left: 10px;
}

.ProjectsItem td.projectTitle {
    max-width: 200px;
}

.ProjectsItem td.featureQuantity {
    text-align: center;
    width: 100px;
}

.ProjectsItem td.featureQuantity span {
    color: #1A5E9B;
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 7.5px;
    background-color: rgb(26, 94, 155, 0.12);
}

.ProjectsItem td.submissionDate span {
    background-color: #F8F9FA;
    font-size: 12px;
    padding: 5px 5px;
    border: 1.5px solid rgb(27, 43, 65, 0.08);
    border-radius: 7.5px;
    white-space: nowrap;
}

.ProjectsItem td.creationDate span {
    background-color: #F8F9FA;
    font-size: 12px;
    padding: 5px 5px;
    border: 1.5px solid rgb(27, 43, 65, 0.08);
    border-radius: 7.5px;
    white-space: nowrap;
}

.ProjectsItem td.projectStatus .statusContainer {
    white-space: nowrap;
    padding: 5px 5px;
    background-color: rgb(248, 249, 250);
    border-radius: 7.5px;
    font-size: 12px;
}

.ProjectsItem td.projectStatus .statusContainer.inProgress {
    color: #DFB300;
    border: 1.5px solid #DFB300;
}

.ProjectsItem td.projectStatus .statusContainer.inReview {
    color: rgb(0, 150, 255);
    border: 1.5px solid rgb(0, 150, 255);
}

.ProjectsItem td.projectStatus .statusContainer.completed {
    color: rgb(80, 200, 120);
    border: 1.5px solid rgb(80, 200, 120);
}

.ProjectsItem td.projectStatus .statusColor {
    width: 7.5px;
    height: 7.5px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
}

.ProjectsItem .projectStatus .statusColor.inProgress {
    background-color: #DFB300;
    box-shadow: 0 0 5px #DFB300;
}

.ProjectsItem .projectStatus .statusColor.inReview {
    background-color: rgb(0, 150, 255);
    box-shadow: 0 0 5px rgb(0, 150, 255);
}

.ProjectsItem .projectStatus .statusColor.completed {
    background-color: rgb(80, 200, 120);
    box-shadow: 0 0 5px rgb(80, 200, 120);
}

.ProjectsItem .projectStatus .statusText {
    display: inline-block;
    font-weight: bold;
    font-size: 11px;
    white-space: nowrap;
}

.ProjectsItem td.actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 15px;
    padding-top: 20px;
    /* background-color: yellow; */
}

.ProjectsItem td.actions .updateButton {
    margin-right: 5px;
}

.ProjectsItem td.actions button.updateButton {
    background-color: #FFFFFF;
    /* width: fit-content; */
    /* border-radius: 7.5px; */
    border: 5px solid black;
    padding: 5px 5px;
    color: white;
    border: none;
    cursor: pointer;
}

.ProjectsItem td.actions button.updateButton:hover {
    background-color: rgb(26, 95, 156, 0.08);
}

.ProjectsItem td.actions button.deleteButton {
    background-color: #FFFFFF;
}

.ProjectsItem td.actions button.deleteButton:hover {
    background-color: rgb(26, 95, 156, 0.08);
}

.ProjectsItem .projectsItemModalContainer .modal {
    margin-top: 40px;
}

.ProjectsItem .projectsItemModalContainer .modal .modal-content {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.ProjectsItem .projectsItemModalContainer .modal .modal-content .modal-header {
    border: none;
    padding: 5px 10px;
}

.ProjectsItem .projectsItemModalContainer .modal .modal-content .modal-header .modal-title {
    font-size: 20px;
    font-weight: 600;
}

.ProjectsItem .projectsItemModalContainer .modal .modal-content .modal-header .closeDeleteModal {
    cursor: pointer;
}

.ProjectsItem .projectsItemModalContainer .modal .modal-content .modal-header .closeDeleteModal:hover {
    border: 1px solid #000000;
    border-radius: 50%;
}

.ProjectsItem .projectsItemModalContainer .modal .modal-content .modal-body {
    padding: 5px 10px;
    font-size: 16px;
}

.ProjectsItem .projectsItemModalContainer .modal .modal-content .modal-footer {
    /* background-color: pink; */
    display: flex;
    width: 100%;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    /* border: none; */
    padding: 10px;
}

.ProjectsItem .projectsItemModalContainer .modal .modal-content .modal-footer button {
    width: 100%;
    margin: 0px 5px;
    padding: 5px 10px;
    border-radius: 7.5px;
    /* border: none; */
    cursor: pointer;
}

.ProjectsItem .projectsItemModalContainer .modal .modal-content .modal-footer button.cancelButton {
    background-color: #FFFFFF;
    color: #192434;
    border: 1px solid rgb(28, 55, 90, 0.16);
}

.ProjectsItem .projectsItemModalContainer .modal .modal-content .modal-footer button.cancelButton:hover {
    background-color: rgb(26, 95, 156, 0.08);
}

.ProjectsItem .projectsItemModalContainer .modal .modal-content .modal-footer button.deleteButton {
    background-color: #E21D12;
}

.ProjectsItem .projectsItemModalContainer .modal .modal-content .modal-footer button.deleteButton:hover {
    border: 1px solid #E21D12;
}