.Users {
    height:100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding: 40px;
}

.Users .usersHeadingContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Users .usersHeadingContainer .section1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 25px;
    font-weight: 700;
}

.Users .usersHeadingContainer .section2 button {
    width: fit-content;
    font-size: 15px;
    background-color: #1A5E9B;
    padding: 5px 10px;
    font-weight: 600;
    border-radius: 7.5px;
}

.Users .usersMainSection {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px 0px;
    border-radius: 10px;
    overflow: auto;
}