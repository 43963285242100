.PatentData .annotationHighlight {
    background-color: rgba(255, 255, 0, 0.6);
}

.PatentData h4 {
    text-align: center;
}

.PatentData p {
    font-size: 14px;
}

.PatentData .documentMetadata {
    font-size: 14px;
    /* width: fit-content; */
    background-color: rgba(26, 56, 96, 0.08);;
    padding: 5px;
    border-radius: 5px;
}

.PatentData .documentMetadata .documentMetadataItem {
    display: flex;
    justify-content: flex-start;
    margin: 2px 0px;
}

.PatentData .documentMainContent .documentMainContentContainer {
    margin-top: 20px;
    padding: 5px;
    border-radius: 5px;
    background-color: rgba(26, 56, 96, 0.03);
}

.PatentData .documentMainContent .documentMainContentContainer .documentSectionHeading {
    font-size: 20px;
    font-weight: 700;
    background-color: rgba(26, 56, 96, 0.02);
    padding: 0px 5px;
    border-radius: 5px;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.PatentData .documentMainContent .documentMainContentContainer .documentSectionHeading:hover {
    background-color: rgba(26, 56, 96, 0.05);
    cursor: pointer;
}

.PatentData .documentMainContent .documentMainContentContainer .hiddenContent {
    display: none;
}