.PhotoGallery {
    height: 100%;
    width: 100%;
    background-color: #F6F7F9;
    border-radius: 10px 10px 0px 0px;
    border: 4px solid #192434;
    display: flex;
    flex-direction: column;
}

.PhotoGallery .topContent {
    height: 40px;
    display: flex;
    align-items: center;
    background-color: #192434;
    justify-content: space-between;
    padding: 5px;
}

.PhotoGallery .topContent .closeButton {
    cursor: pointer;
}

.PhotoGallery .topContent .imageAnnotation {
    display: flex;
    align-items: center;
    width: 90%;
}

.PhotoGallery .topContent .imageAnnotation .imageAnnotationButton {
    background-color: rgb(255, 255, 255, 0.1);
    border-radius: 20px;
    width: fit-content;
    padding: 2.5px 10px;
    font-size: 12px;
    font-weight: 600;
}

.PhotoGallery .topContent .imageAnnotation .imageAnnotationButton:hover {
    background-color: rgb(255, 255, 255, 0.2);
}

.PhotoGallery .bottomContent {
    height: 100%;
    overflow-y: auto;
}

.PhotoGallery .bottomContent .imageGallery {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.PhotoGallery .bottomContent .imageGallery .no-images {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(0, 0, 0, 0.1);
}

.PhotoGallery .bottomContent .imageGallery .fullImageContainer {
    height: 70%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5px;
}

.PhotoGallery .bottomContent .imageGallery .fullImageContainer .annotationMatchContainer {
    height: 25px;
    text-align: center;
}

.PhotoGallery .bottomContent .imageGallery .fullImageContainer .full {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    color: white;
    text-align: center;
}

.PhotoGallery .bottomContent .imageGallery .fullImageContainer .full img {
    max-width: 100%;
    height: 100%;
    border-radius: 10px;
    border: 2px solid rgb(26, 56, 96, 0.1);
}

.PhotoGallery .bottomContent .imageGallery .thumbs {
    background-color: #FFFFFF;
    justify-self: flex-end;
    height: 30%;
    width: 100%;
    display: flex;
    align-items: center;
    overflow-x: auto;
    padding: 10px 0px;
}

.PhotoGallery .bottomContent .imageGallery .thumbs::-webkit-scrollbar {
    height: 5px;
}

.PhotoGallery .bottomContent .imageGallery .thumbs::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.5);
}

.PhotoGallery .bottomContent .imageGallery .thumbs::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.1);
}

.PhotoGallery .bottomContent .imageGallery .thumbs::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.7);
}

/* .PhotoGallery .bottomContent .imageGallery .thumbs .sliderLeft {
    position: absolute;
    left: 0px;
    bottom: 0px;
    cursor: pointer;
    background-color: blue;
    height: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.PhotoGallery .bottomContent .imageGallery .thumbs .sliderRight {
    position: absolute;
    right: 0px;
    bottom: 0px;
    cursor:cell;
    background-color: red;
    height: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
} */

.PhotoGallery .bottomContent .imageGallery .annotationNotificationContainer {
    display: flex;
    justify-content: center;
}

.PhotoGallery .bottomContent .imageGallery .annotationNotification {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px;
}

.PhotoGallery .topContent .dropdown-menu {
    max-height: 25vh;
    width: 100%;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 3px 3px #F6F7F9;
    overflow-y: scroll;
    font-size: 13px;
}

.PhotoGallery .topContent .dropdown-menu select {
    font-size: 13px;
}

.PhotoGallery .topContent .dropdown-menu .formSubmitButton button {
    background-color: #158444;
    color: white;
    font-size: 13px;
    font-weight: 600;
}

.PhotoGallery .topContent .dropdown-menu .formSubmitButton button:hover {
    background-color: #0A7637;
}

.PhotoGallery .topContent .dropdown-menu .formSubmitButton button:disabled {
    background-color: #158444;
    opacity: 0.8;
    cursor: not-allowed;
}