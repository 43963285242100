.ProjectItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-wrap: wrap;
    width: 100%;
    cursor: pointer;
    text-align: left;
    padding: 2px 5px;
}

.ProjectItem .projectItemInfo {
    width: 90%;
}

.ProjectItem .projectItemInfo div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ProjectItem .projectItemStatus {
    width: 10%;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ProjectItem .projectItemStatus span.status {
    width: 7.5px;
    height: 7.5px;
    border-radius: 50%;
}

.ProjectItem .projectItemStatus span.status.inProgress {
    background-color: orange;
    box-shadow: 0 0 5px orange;
}

.ProjectItem .projectItemStatus span.status.inReview {
    background-color: rgb(0, 150, 255);
    box-shadow: 0 0 5px rgb(0, 150, 255);
}

.ProjectItem .projectItemStatus span.status.completed {
    background-color: rgb(80, 200, 120);
    box-shadow: 0 0 5px rgb(80, 200, 120);
}