.HelpList {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
}

.HelpList .helpHeader1 {
    font-size: 18px;
    font-weight: 600;
    padding-top: 10px;
}

.HelpList .helpHeader2 {
    font-size: 12px;
    font-weight: 400;
    padding-bottom: 10px;
}

.HelpList .contentContainer {
    height: 100%;
}

.HelpList .contentContainer .section {
    padding: 5px 10px;
    margin-bottom: 5px;
    background-color: rgb(26, 94, 155, 0.08);
    border-radius: 5px;
}

.HelpList .contentContainer .section div.sectionHeading {
    font-weight: 500;
    font-size: 16px;
}

.HelpList .contentContainer .section div.sectionHeading.show {
    /* border-bottom: 1px solid #D0D5DD; */
    margin-bottom: 5px;
}

.HelpList .contentContainer .section div.sectionHeading:hover {
    cursor: pointer;
}

.HelpList .contentContainer .section div.sectionContent {
    display: none;
    padding: 5px 20px 2px 20px;
    font-size: 15px;
    background-color: rgb(255, 255, 255, 0.6);
    border-radius: 5px;
}

.HelpList .contentContainer .section div.sectionContent.show {
    display: block;
}