.Blank {
    display: flex;
    align-items: flex-start;
    height: 95vh;
    text-align: center;
    margin: 0 auto;
    background-color: lightgray;
    padding-top: 120px;
}

.Blank h4 {
    font-weight: normal;
}