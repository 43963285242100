.SearchHistory {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding: 20px 40px;
    font-size: 14px;
    color: #192434;
}

.SearchHistory .searchHistoryHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1.5px solid rgb(217, 217, 217, 0.42);
}

.SearchHistory .searchHistoryHeader .container1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.SearchHistory .searchHistoryHeader .container1 .pageTitle {
    font-size: 24px;
    font-weight: 700;
}

.SearchHistory .searchHistoryHeader .container1 .projectTitle {
    font-size: 13px;
    font-weight: 500;
    color: rgb(28, 48, 74, 0.65);
    white-space: nowrap;
}

.SearchHistory .searchHistoryHeader .container2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.SearchHistory .searchHistoryHeader .container2 a.cancelButton {
    margin-right: 10px;
    text-decoration: none;
}

.SearchHistory .searchHistoryHeader .container2 button.cancelButton {
    font-size: 13px;
    font-weight: 600;
    border: 1px solid #D0D5DD;
    border-radius: 7.5;
    padding: 7.5px 15px;
    color: #192434;
    background-color: #FFFFFF;
}

.SearchHistory .searchHistoryHeader .container2 button.submitButton {
    font-size: 12px;
    font-weight: 600;
    border-radius: 7.5;
    padding: 7.5px 15px;
    color: #FFFFFF;
    background-color: rgb(26, 94, 155, 0.9);
    white-space: nowrap;
}

.SearchHistory .searchHistoryHeader .container2 button.submitButton:hover {
    background-color: rgb(26, 94, 155, 1);
}

.SearchHistory .fileUploadContainer {
    display: flex;
    justify-content: space-between;
}
.SearchHistory .fileUploadContainer .fileUpload {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.SearchHistory .fileUploadContainer .fileUpload .fielUploadHeading {
    font-weight: 600;
    font-size: 16px;
}

.SearchHistory .fileUploadContainer .fileUpload:first-child {
    padding-right: 10px;
}

.SearchHistory .fileUploadContainer .fileUpload:last-child {
    padding-left: 10px;
}

.SearchHistory .fileUploadContainer .fileUpload form {
    width: 100%;
    height: 100%;
    background-color:#FFFFFF;
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid rgb(26, 56, 96, 0.1) ;
}

.SearchHistory .fileUploadContainer .fileUpload form input {
    font-size: 13px;
}

.SearchHistory .fileUploadContainer .fileUpload button {
    margin-top: 5px;
    font-size: 14px;
    font-weight: 600;
    padding: 5px 10px;
    background-color: rgb(26, 94, 155, 0.9);
    border: none;
}

.SearchHistory .fileUploadContainer .fileUpload button:hover {
    background-color: rgb(26, 94, 155, 1);
}

.SearchHistory .uploading {
    display: block;
    text-align: center;
}

.SearchHistory .uploadDefault {
    display: none;
}

.SearchHistory .orbitSearchHistoryFileUploadError {
    display: none;
}

.SearchHistory .showOrbitSearchHistoryFileUploadError {
    display: block;
    text-align: center;
}

.SearchHistory .patentListFileUploadError {
    display: none;
}

.SearchHistory .showPatentListFileUploadError {
    display: block;
    text-align: center;
}

.SearchHistory .searchHistoryFormContainer {
    height: 100%;
    overflow: auto;
    padding-top: 30px;
    display: flex;
}

.SearchHistory .searchHistoryFormContainer .searchHistoryForm {
    width: 100%;
    height: 100%;
    display: flex;
}

.SearchHistory .searchHistoryFormContainer .searchHistoryForm .formCard {
    height: 100%;
    width: 25%;
    background-color: #FFFFFF;
    /* overflow-y: auto; */
    border: 1px solid rgb(26, 56, 96, 0.1);
    display: flex;
    flex-direction: column;
    border-radius: 10px;
}

.SearchHistory .searchHistoryFormContainer .searchHistoryForm .formCard .searchHistoryFormHeading {
    font-weight: 600;
    font-size: 16px;
    padding: 5px 10px;
    height: 60px;
    /* overflow: auto; */
    border-bottom: 1px solid rgb(26, 56, 96, 0.1);
}

.SearchHistory .searchHistoryFormContainer .searchHistoryForm .formCard .formContent {
    padding: 10px;
    font-size: 13px;
    overflow: auto;
}

.SearchHistory .searchHistoryFormContainer .searchHistoryForm .formCard:not(:last-child) {
    margin-right: 10px;
}

.SearchHistory .searchHistoryFormContainer .searchHistoryForm .formCard textarea {
    width: 100%;
    resize: none;
    font-size: 14px;
}

@media screen and (max-width: 768px) {
    .SearchHistory .searchHistoryFormContainer {
        padding-top: 0px;
    }
    .SearchHistory .searchHistoryFormContainer .searchHistoryForm .formCard .searchHistoryFormHeading {
        font-size: 13px;
    }
    .SearchHistory .searchHistoryFormContainer .searchHistoryForm .formCard .formContent {
        font-size: 11px;
    }
    .SearchHistory .searchHistoryFormContainer .searchHistoryForm .formCard textarea {
        font-size: 11px;
    }
}