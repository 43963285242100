.Projects {
    padding: 10px 40px;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    font-size: 14px;
}

.Projects .section1 {
    /* background-color: blue; */
    padding: 10px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Projects .section1 .section1p1 {
    font-weight: 700;
    font-size: 25px;
    margin: 0px;
}

.Projects .section1 .section1p2 button.createProject {
    background-color: #1A5E9B;
    border-radius: 7.5px;
    padding: 5px 10px;
}

.Projects .section2 {
    height: 100%;
    width: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    border-radius: 7.5px;
    background-color: #FFFFFF;
    border: 1px solid rgb(26, 56, 96, 0.1);
}

.Projects .section2 .projectsTable {
    width: 100%;
    height: 100%;
    overflow: auto;
}

.Projects .section2 .projectsTable table {
    width: 100%;
    padding:10px;
}

.Projects .section2 .projectsTable table thead {
    background-color: #193B67;
    /* text-align: center; */
    width: 100%;
    height: 45px;
    color: white;
    font-size: 14px;
    padding: 10px;
    position: sticky;
    top: 0;
}

.Projects .section2 .projectsTable th {
    padding: 0px 10px;
}

.Projects .section2 .navigation {
    height: 50px;
    border-top: 1px solid rgb(26, 56, 96, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px;
    /* background-color: aqua; */
}
