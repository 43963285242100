.UsersItem {
    background-color: #FFFFFF;
    width: 49%;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    border: 1px solid rgb(26, 56, 96, 0.1);
}

.UsersItem .firstContainer {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    border-bottom: 1.5px solid rgb(26, 56, 96, 0.1);
}

.UsersItem .firstContainer .section1 {
    display: flex;
    height: 100%;
    justify-content: flex-start;
}

.UsersItem .firstContainer .section1 .userInfo {
    padding-left: 10px;
}

.UsersItem .firstContainer .section1 .userInfo .usersItemName {
    font-weight: 700;
    font-size: 16px;
}

.UsersItem .firstContainer .section2 {
    display: flex;
    width: 60%;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
}

.UsersItem .firstContainer .section2 button {
    width: fit-content;
    font-size: 13px;
    padding: 2.5px 7.5px;
    font-weight: 600;
    border-radius: 7.5px;
}

.UsersItem .firstContainer .section2 .deleteButton button.deleteButtonModalTrigger {
    background-color: #FCE8E7;
    padding: 5px 7.5px;
    margin-left: 5px;
}

.UsersItem .firstContainer .section2 .deleteButton button.deleteButtonModalTrigger:hover {
    background-color: rgb(226, 29, 18, 0.2);
}

.UsersItem .firstContainer .section2 .resetPassword button {
    border: 1px solid rgb(26, 94, 155, 0.15);
    background-color: rgb(26, 94, 155, 0.9);
    color: #FFFFFF;
    font-size: 13px;
    margin-left: 5px;
}

.UsersItem .firstContainer .section2 .resetPassword button:hover {
    background-color: rgb(26, 94, 155, 1);
}

.UsersItem .secondContainer {
    height: 50%;
    display: flex;
    font-size: 13px;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
}

.UsersItem .secondContainer .section1 {
    display: flex;
    justify-content: flex-start;
    align-items: center;

}

.UsersItem .secondContainer .section1 .userType {
    font-weight: 600;
    color: #1A5E9B;
    background-color: rgb(26, 94, 155, 0.1);
    padding: 5px 10px;
    border-radius: 10px;
    margin-right: 10px;
}

.UsersItem .secondContainer .section1 .userCreationDate {
    font-weight: 500;
    color: rgb(25, 36, 52, 0.9);
    border-left: 1px solid rgb(27, 43, 65, 0.08);
    padding: 5px 10px;
}

.UsersItem .secondContainer .section2 .userCompany {
    font-weight: 500;
}

.UsersItem .secondContainer .section2 .userCompany span:first-child {
    color: rgb(28, 48, 74, 0.65);
}

.UsersItem .dropdown-menu {
    width: 30vw;
}

.UsersItem .resetPassword .formContainer {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.UsersItem .resetPassword .formContainer .header {
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgb(26, 56, 96, 0.1);
    padding: 5px 10px;
}

.UsersItem .resetPassword .formContainer .header .left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.UsersItem .resetPassword .formContainer .header .left .first {
    padding: 5px;
    border: 2px solid rgb(26, 56, 96, 0.08);
    border-radius: 10px;
}

.UsersItem .resetPassword .formContainer .header .left .second .sec1 {
    font-size: 15px;
    font-weight: 600;
    text-align: left;
}

.UsersItem .resetPassword .formContainer .header .left .second .sec2 {
    font-size: 13px;
    font-weight: 400;
    text-align: left;
}

.UsersItem .resetPassword .formContainer .header .left .second .sec2 span {
    margin-left: 5px;
    padding: 2.5px 5px;
    border-radius: 5px;
    border: 1px solid rgb(27, 43, 65, 0.15);
    font-weight: 600;
    color: #1A5E9B;
}

.UsersItem .resetPassword .formContainer .header .right svg {
    cursor: pointer;
}

.UsersItem .resetPassword .formContainer .header .right svg:hover {
    border: 1px solid #000000;
    border-radius: 50%;
}

.UsersItem .resetPassword .formContainer form {
    font-size: 14px;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.UsersItem .resetPassword .formContainer form .formContent {
    overflow: auto;
    padding: 10px;
    text-align: left;
    font-weight: 600;
}

.UsersItem .resetPassword .formContainer form .formContent input {
    font-size: 14px;
    border: 1px solid rgb(28, 48, 74, 0.15);
}

.UsersItem .formContainer .formButtonContainer {
    height: 10%;
    padding: 5px 10px;
    height: fit-content;
    border-top: 1px solid rgb(26, 56, 96, 0.1);
}

.UsersItem .formContainer .formButtonContainer button {
    height: fit-content;
    padding: 10px;
    margin: 10px;
    color: #FFFFFF;
    font-weight: 500;
    font-size: 14px;
}

.UsersItem .formContainer .formButtonContainer button.cancel {
    color: black;
    border: 1px solid rgb(28, 48, 74, 0.15);
    background-color: #FFFFFF;
}

.UsersItem .formContainer .formButtonContainer button.cancel:hover {
    background-color: #F5F5F5;
}

.UsersItem .formContainer .formButtonContainer button.submit {
    border: 1px solid #1A5E9B;
}

.UsersItem .formContainer .formButtonContainer button.submit:hover {
    border: 0.5px solid #1A5E9B;
}

.UsersItem .formContainer .passwordResetNotification {
    font-size: 13px;
}

.UsersItem .formContainer .showNewPassword {
    background-color: rgb(26, 56, 96, 0.1);
    border-radius: 0px 0px 7.5px 7.5px;
    padding: 5px 10px;
    font-size: 14px;
}

.UsersItem .deleteButton .modal .modal-dialog .modal-content .modal-header {
    border: none;
    padding: 5px 10px;
}

.UsersItem .deleteButton .modal .modal-dialog .modal-content .modal-header .modal-title {
    font-size: 20px;
    font-weight: 600;
}

.UsersItem .deleteButton .modal .modal-dialog .modal-content .modal-body {
    padding: 5px 10px;
    font-size: 14px;
}

.UsersItem .deleteButton .modal .modal-dialog .modal-content .modal-footer {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
}

.UsersItem .deleteButton .modal .modal-dialog .modal-content .modal-footer button {
    width: 50%;
    padding: 7.5px 10px;
    font-weight: 600;
}

.UsersItem .deleteButton .modal .modal-dialog .modal-content .modal-footer button.closeButton {
    background-color: #FFFFFF;
    border: 1px solid rgb(26, 56, 96, 0.15);
    color: #192434;
}

.UsersItem .deleteButton .modal .modal-dialog .modal-content .modal-footer button.closeButton:hover {
    background-color: rgb(26, 56, 96, 0.15);
}

.UsersItem .deleteButton .modal .modal-dialog .modal-content .modal-footer button.deleteUserButton {
    background-color: rgb(226, 29, 18, 0.9);
    border: 1px solid rgb(226, 29, 18, 0.9);
}

.UsersItem .deleteButton .modal .modal-dialog .modal-content .modal-footer button.deleteUserButton:hover {
    background-color: rgb(226, 29, 18, 1);
}

.UsersItem .disableUser {
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: 500;
    color: rgb(28, 48, 74, 0.8);
    padding-right: 10px;
}

.UsersItem .disableUser .switch {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 23px;
    margin-right: 3px;
  }
  
  /* Hide default HTML checkbox */
  .UsersItem .disableUser .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .UsersItem .disableUser .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #E21D12;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .UsersItem .disableUser .slider:before {
    position: absolute;
    content: "";
    height: 15px;
    width: 15px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .UsersItem .disableUser input:checked + .slider {
    background-color: #158444;
  }
  
  .UsersItem .disableUser input:focus + .slider {
    box-shadow: 0 0 1px #158444;
  }
  
  .UsersItem .disableUser input:checked + .slider:before {
    -webkit-transform: translateX(15px);
    -ms-transform: translateX(15px);
    transform: translateX(22px);
  }
  
  /* Rounded sliders */
  .UsersItem .disableUser .slider.round {
    border-radius: 20px;
  }
  
  .UsersItem .disableUser .slider.round:before {
    border-radius: 50%;
  }