.NewProjectForm {
    height: fit-content;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.NewProjectForm.modal-dialog {
    min-width: 70%;
}

.NewProjectForm .modal-content.formContainer {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.NewProjectForm .formContainer .header {
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgb(26, 56, 96, 0.1);
    padding: 5px 10px;
}

.NewProjectForm .formContainer .header .left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.NewProjectForm .formContainer .header .left .second .sec1 {
    font-size: 15px;
    font-weight: 600;
    text-align: left;
}

.NewProjectForm .formContainer .header .left .second .sec2 {
    font-size: 11px;
    font-weight: 400;
    text-align: left;
}

.NewProjectForm .formContainer .header .right svg {
    cursor: pointer;
}

.NewProjectForm .formContainer .header .right svg:hover {
    border: 1px solid #000000;
    border-radius: 50%;
}

.NewProjectForm .formContainer form {
    font-size: 14px;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.NewProjectForm .formContainer form .formContent {
    overflow: auto;
    padding: 10px;
    text-align: left;
    font-weight: 600;
}

.NewProjectForm .formContainer form .formContent input, 
.NewProjectForm .formContainer form .formContent select,
.NewProjectForm .formContainer form .formContent textarea {
    font-size: 14px;
    border: 1px solid rgb(28, 48, 74, 0.15);
}

.NewProjectForm .formContainer form .formContent .checkIsValidProjectNum {
    width: 100%;
    border-radius: 5px;
    padding: 2px;
}

.NewProjectForm .formContainer form .formContent .checkProjNum {
    text-align: center;
    margin: 0px 5px;
    width: 100%;
    border-radius: 5px;
    padding: 2px;
    border-radius: 50%;
}

.NewProjectForm .formContainer form .formContent .groupContainer1 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
}

.NewProjectForm .formContainer form .formContent .groupContainer1 .projectNumber {
    width: 70%;
    padding-right: 15px;
}

.NewProjectForm .formContainer form .formContent .groupContainer1 .submissionDate {
    width: 30%;
}

.NewProjectForm .formContainer form .formContent .groupContainer2 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
}

.NewProjectForm .formContainer form .formContent .groupContainer2 .projTitle {
    width: 55%;
    padding-right: 15px;
}

.NewProjectForm .formContainer form .formContent .groupContainer2 .company {
    width: 45%;
}

.NewProjectForm .formContainer form .formContent .addElementContainer {
    display: flex;
    flex-direction: column;
    padding: 5px 10px;
    border: 1px dashed rgb(28, 48, 74, 0.15);
    border-radius: 7.5px;
    margin-top: 10px;
}

.NewProjectForm .formContainer form .formContent .addElementContainer .counterAdder {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.NewProjectForm .formContainer form .formContent .addElementContainer .counterAdder .addElementButton {
    background-color: #158444;
    display: flex;
    align-items: center;
    border-radius: 7.5px;
    color: #FFFFFF;
    font-weight: 600;
    width: 30%;
}

.NewProjectForm .formContainer form .formContent .addElementContainer .counterAdder .addElementButton:hover {
    border: 2px solid #158444;
}

.NewProjectForm .formContainer .formButtonContainer {
    height: 10%;
    padding: 5px 10px;
    height: fit-content;
    border-top: 1px solid rgb(26, 56, 96, 0.1);
}

.NewProjectForm .formContainer .formButtonContainer button {
    height: fit-content;
    padding: 10px;
    margin: 10px;
    color: #FFFFFF;
    font-weight: 500;
    font-size: 14px;
}

.NewProjectForm .formContainer .formButtonContainer button.cancel {
    color: black;
    border: 1px solid rgb(28, 48, 74, 0.15);
}

