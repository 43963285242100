.PhotoGalleryThumb {
    height: 100%;
    min-width: 150px;
    cursor: pointer;
    position: relative;
    /* border: 1px solid gray; */
    /* background-color: rgb(0, 0, 0, 0.7); */
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 5px;
}

.PhotoGalleryThumb img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  transition: transform 5s;
  border: 2px solid rgb(26, 94, 155, 0.3);
  object-fit:cover;
}

.PhotoGalleryThumb img:hover {
  /* background: linear-gradient( rgb(25, 36, 52, 0),rgb(25, 36, 52, 0.1));
  border-radius: 10px; */
  border: 2px solid rgb(26, 94, 155, 0.5);
}

/* .PhotoGalleryThumb:hover img {
  transform: scale(1.1);
} */

.PhotoGalleryThumb.active img {
  /* background-color: rgb(0, 0, 0, 0.5); */
  
  border: 2px solid rgb(26, 94, 155, 0.6);
}

.PhotoGalleryThumb.active .activeOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient( rgb(25, 36, 52, 0),rgb(25, 36, 52, 0.4));
  border-radius: 10px;
}