.Document {
    height: 100%;
    overflow-y: auto;
    width: 100%;
    border-radius: 7.5px;
    border: 4px solid #DBEEFF;
    z-index: 2;
    position: relative;
}

.Document .manualOverrideContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    width: fit-content;
    top: -4px;
    right: 0px;
    border: none;
    background-color: #DBEEFF;
    border-radius: 0px 0px 0px 7.5px;
}

.Document .manualOverrideContainer button.showDocumentButton {
    color: #1B2B41;
}

.Document .manualOverrideContainer button.manualOverrideButton {
    background-color: #409963;
    color: white;
    border: 4px solid #DBEEFF;
    width: fit-content;
    border-radius: 7.5px;
    font-size: 13px;
    font-weight: 600;
}

.Document .manualOverrideContainer .manualOverrideButton:hover {
    background-color: #0A7637;
}

.Document .mainDocument.NPD {
    position: relative;
    top: 60px;
}

/* Handle NPL with sticky nav */
.Document .mainDocument.NPD nav {
    position: relative;
}


.Document .mainDocument .pdfButton {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0px;
    left: 0px;
    border: none;
}

.Document .mainDocument .pdfButtonContent {
    background-color: #DBEEFF;
    border-radius: 0px 0px 7.5px 0px;
    border: 4px solid #DBEEFF;
    position: relative;
    top: -4px;
    left: -4px;
}

.Document .mainDocument .pdfButton .pdfButtonContent button {
    width: fit-content;
    border: none;
    background-color: #1A5E9B;
    color: white;
    border-radius: 5px;
    font-size: 13px;
    font-weight: 600;
}

.Document .mainDocument .pdfButton .pdfButtonContent button:hover {
    background-color: #104B81;
}

.Document .mainDocument {
    padding: 30px 0px;
}

.Document.documentLoadingSpinner {
    display: flex;
    justify-content: center;
    align-items: center;
}

.Document.documentLoadingSpinner .card-body {
    display: flex;
    justify-content: center;
    align-items: center;
}

.Document.documentLoadingSpinner .spinner-border {
    width: 6rem;
    height: 6rem;
    font-size: 1.5rem;
    color: #1A5E9B;
}

.Document h2, .Document h3 {
    text-align: center;
}

.Document .warning {
    max-height: 25px;
    background-color: rgba(255, 165, 0, 1);
    position: sticky;
    top: 0px;
}

.Document .warning p {
    font-size: 17px;
}

.Document .pdfButton {
    color: rgba(0, 0, 0, 0.7);
    border: 1px solid rgba(0, 0, 0, 0.4);
}

/* .Document .abstractMatch {
    background-color: rgba(255, 255, 0, 0.6);
    cursor: pointer;
} */

.Document embed {
    width: 100%;
    height: 80vh;
    border: 2px solid rgba(0, 0, 0, 0.4);
}

.Document .manualTextAnnotation {
    width: 100%;
}

.Document mark {
    padding: 2px 0px 3px 0px;
}

.Document .mainDocument .keyword1 {
    background-color: #e1affd;
    border-radius: 3px;
}

.Document .mainDocument .keyword2 {
    background-color: #90ee90;
    border-radius: 3px;
}

.Document .mainDocument .keyword3 {
    background-color: #0398dc;
    border-radius: 3px;
}

.Document .mainDocument .keyword4 {
    background-color: #fdbb6d;
    border-radius: 3px;
}

.Document .mainDocument .keyword5 {
    background-color: #ff595e;
    border-radius: 3px;
}

.Document .mainDocument .keyword6 {
    background-color: #eea2ad;
    border-radius: 3px;
}

.Document .mainDocument .keyword7 {
    background-color: #bd9a73;
    border-radius: 3px;
}

.Document .mainDocument .keyword8 {
    background-color: #bebebe;
    border-radius: 3px;
}

.Document .annotationHighlight[data-title]:hover:after {
    opacity: 1;
    transition: all 0.3s ease 0.1s;
    visibility: visible;
}

.Document .annotationHighlight[data-title]:after {
    content: attr(data-title);
    background-color: rgb(245,245,245);
    border-radius: 5px;
    font-weight: 700;
    color: black;
    font-size: 1rem;
    position: absolute;
    padding: 1px 5px 1px 5px;
    top: -40px;
    left: 100px;
    transform: translateX(-100%);
    white-space: nowrap;
    box-shadow: 1px 1px 3px #222222;
    opacity: 0;
    border: 1px solid grey;
    /* z-index: 99999; */
    visibility: hidden;
}

.Document .annotationHighlight[data-title] {
    position: relative;
    cursor: pointer;
}

.Document .annotationHighlight[data-title] mark:after {
    top: -40px;
    left: 200px;
}

.Document .annotationHighlight[data-title] > mark mark:after {
    top: -40px;
    left: 300px;
}

.Document .annotationHighlight[data-title] > mark mark mark:after {
    top: -40px;
    left: 400px;
}

.Document .annotationHighlight[data-title] > mark mark mark mark:after {
    top: -40px;
    left: 500px;
}