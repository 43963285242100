.Nav {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px 0px 20px;
    background-color: #FFFFFF;
    /* border-bottom: 2px solid #F6F7F9; */
}

.Nav .navLinks {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.Nav .navLinks .navLink {
    margin: 0px 15px;
    padding: 0px 10px 10px 10px;
    border-bottom: #FFFFFF 3px solid;
}

.Nav .navLinks .navLink svg {
    height: 15px;
}

.Nav .navLinks .navLink:hover {
    border-bottom: #1A5E9B 3px solid;
}

.Nav .navLinks .navLink.active {
    border-bottom: #1A5E9B 3px solid;
}

.Nav .navLinks .navLink.active svg path{
    fill: #1A5E9B;
    fill-opacity: 1;
}

.Nav .dropdown {
    cursor: default;
}

.Nav .profileDropDown {
    cursor: pointer;
}

.Nav .profileDropDown .dropdown-toggle {
    padding: 3px 10px;
}

.Nav .profileDropDown .dropdown-toggle:hover {
    background-color: rgba(26, 94, 155, 0.05);
}

.Nav .profileDropDown .dropdown-menu {
    box-shadow: 0px 0px 5px 5px #F6F7F9;
    width: 20vw;
}

.Nav .profileDropDown .dropdown-menu div.userInfo {
    cursor: default;
    display: flex;
    justify-content: space-around;
    padding: 10px;
}

.Nav .profileDropDown .dropdown-menu .userInfo .profileImage img {
    border-radius: 50%;
    background-color: purple;
}

.Nav .profileDropDown .dropdown-item {
    border-top: 2px solid #F6F7F9;
}

.Nav .profileDropDown .dropdown-item:hover {
    background-color: rgba(26, 94, 155, 0.05);
    color: #1A5E9B;
}