.ProjectDetails {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    /* padding: 0px 10px; */
    font-size: 14px;
}

/* .ProjectDetails ::-webkit-scrollbar {
    max-width: 5px;
}

.ProjectDetails ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.4);
}

.ProjectDetails ::-webkit-scrollbar-track {
    border-radius: 0px;
    max-width: 5px;
    background-color: rgba(0, 0, 0, 0.1);
}

.ProjectDetails ::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.5);
}

.ProjectDetails ::-webkit-scrollbar-track:hover {
    background-color: rgba(0, 0, 0, 0.2);
} */

.ProjectDetails .content {
    height: 100%;
    display: flex;
    padding: 5px 40px;
}

.ProjectDetails .content .details {
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0px;
}
.ProjectDetails .content .projectElements {
    position: relative;
    width: 17vw;
    margin-left: 10px;
    padding: 30px 5px 10px 5px;
    background-color: rgba(26, 56, 96, 0.1);
    border-radius: 10px;
    box-shadow: 0 0 2.5px rgba(0, 0, 0, 0.3);
}

.ProjectDetails .content .projectElements .closeButton {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
}

.ProjectDetails .content .projectElements .closeButton:hover {
    border: 1px solid rgba(26, 56, 96, 0.5);
    border-radius: 50%;
}

.ProjectDetails .content .details .projectTitle {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 20px;
}

.ProjectDetails .content .details .projectTitle .title {
    padding-left: 15px;
    font-size: 18px;
    font-weight: 600;
}

.ProjectDetails .content .details .projectInfo {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 2px solid rgb(26, 56, 96, 0.1);
    border-radius: 10px;
    background-color: #FFFFFF;
}

.ProjectDetails .content .details .projectInfo .top {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid rgb(26, 56, 96, 0.1);
    font-size: 13px;
}

.ProjectDetails .content .details .projectInfo .top .sec1 {
    display: flex;
    align-items: center;
}

.ProjectDetails .content .details .projectInfo .top .sec1 .projNum {
    padding-left: 10px;
    font-weight: 600;
    color: #104B81;
    text-decoration: underline;
}

.ProjectDetails .content .details .projectInfo .top .sec2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid rgb(26, 56, 96, 0.1);
    border-radius: 7.5px;
}

.ProjectDetails .content .details .projectInfo .top .sec2 .sec2p1 {
    border-right: 1px solid rgb(26, 56, 96, 0.1);
    padding: 3px 10px;
}

.ProjectDetails .content .details .projectInfo .top .sec2 .sec2p1 :first-child {
    color: rgb(28, 48, 74, 0.65);
    padding-right: 2px;
    font-weight: 500;
}

.ProjectDetails .content .details .projectInfo .top .sec2 .sec2p1 :last-child {
    color: rgb(25, 36, 52, 0.9);
    font-weight: 600;
}

.ProjectDetails .content .details .projectInfo .top .sec2 .sec2p2 {
    padding: 3px 10px;
}

.ProjectDetails .content .details .projectInfo .top .sec2 .sec2p2 :first-child {
    color: rgb(28, 48, 74, 0.65);
    padding-right: 2px;
    font-weight: 500;
}

.ProjectDetails .content .details .projectInfo .top .sec2 .sec2p2 :last-child {
    color: rgb(25, 36, 52, 0.9);
    font-weight: 600;
}

.ProjectDetails .content .details .projectInfo .bottom {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid rgb(26, 56, 96, 0.02);
}

.ProjectDetails .content .details .projectInfo .bottom .sec1 {
    display: flex;
    height: fit-content;
    text-wrap: nowrap;
}

.ProjectDetails .content .details .projectInfo .bottom .sec1 div {
    padding-bottom: 10px;
}

.ProjectDetails .content .details .projectInfo .bottom .sec1 .headings {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-right: 10px;
    color: rgb(28, 48, 74, 0.65);
    font-weight: 500;
}

.ProjectDetails .content .details .projectInfo .bottom .sec1 .values {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: rgb(25, 36, 52, 0.9);
    font-weight: 600;
}

.ProjectDetails .content .details .projectInfo .bottom .sec1 .values .featureQuantity {
    width: fit-content;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    border: 1px solid rgb(27, 43, 65, 0.13);
    border-radius: 7.5px;
    color: #1A5E9B;
}

.ProjectDetails .content .details .projectInfo .bottom .actionButtons {
    display: flex;
    width: 50%;
    flex-direction: column;
    justify-content: center;
}

.ProjectDetails .content .details .projectInfo .bottom .actionButtons .projectStatus {
    display: flex;
    align-items: center;
}

.ProjectDetails .content .details .projectInfo .bottom .actionButtons .projectStatus .statusContainer {
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-self: center;
    padding: 5px 10px;
    background-color: rgb(248, 249, 250);
    border-radius: 7.5px;
    font-size: 13px;
    font-weight: 700;
}

.ProjectDetails .content .details .projectInfo .bottom .actionButtons .projectStatus .statusContainer .statusColor {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
}

.ProjectDetails .content .details .projectInfo .bottom .actionButtons .projectStatus .statusContainer.inProgress {
    color: #DFB300;
    border: 1.5px solid #DFB300;
}

.ProjectDetails .content .details .projectInfo .bottom .actionButtons .projectStatus .statusContainer.inReview {
    color: rgb(0, 150, 255);
    border: 1.5px solid rgb(0, 150, 255);
}

.ProjectDetails .content .details .projectInfo .bottom .actionButtons .projectStatus .statusContainer.completed {
    color: rgb(80, 200, 120);
    border: 1.5px solid rgb(80, 200, 120);
}


.ProjectDetails .content .details .projectInfo .bottom .actionButtons .projectStatus .statusColor.inProgress {
    background-color: #DFB300;
    box-shadow: 0 0 5px #DFB300;
}

.ProjectDetails .content .details .projectInfo .bottom .actionButtons .projectStatus .statusColor.inReview {
    background-color: rgb(0, 150, 255);
    box-shadow: 0 0 5px rgb(0, 150, 255);
}

.ProjectDetails .content .details .projectInfo .bottom .actionButtons .projectStatus .statusColor.completed {
    background-color: rgb(80, 200, 120);
    box-shadow: 0 0 5px rgb(80, 200, 120);
}

.ProjectDetails .content .details .projectInfo .bottom .actionButtons .searchStrategyNotification {
    font-style: italic;
    font-weight: 600;
    font-size: 13px;
    padding-top: 5px;
}

.ProjectDetails .content .details .projectInfo .bottom .actionButtons .buttonContainer {
    display: flex;
    width: 100%;
    padding: 10px 10px 10px 0px;
}

@media screen and (max-width: 960px) {
    .ProjectDetails .content .details .projectInfo .bottom .actionButtons .buttonContainer {
        flex-direction: column;
    }
    
}

.ProjectDetails .content .details .projectInfo .bottom .actionButtons .buttonContainer a {
    text-decoration: none;
}

.ProjectDetails .content .details .projectInfo .bottom .actionButtons .buttonContainer .orbitSearchHistory {
    white-space: nowrap;
    margin-right: 20px;
}

@media screen and (max-width: 960px) {
    .ProjectDetails .content .details .projectInfo .bottom .actionButtons .buttonContainer .orbitSearchHistory {
        margin-right: 0px;
        margin-bottom: 10px;
    }
    
}

.ProjectDetails .content .details .projectInfo .bottom .actionButtons .buttonContainer .orbitSearchHistory button {
    background-color: #1A5E9B;
    color: #FFFFFF;
    font-weight: 600;
    border-radius: 7.5px;
    padding: 7.5px 20px;
}

.ProjectDetails .content .details .mainContainer {
    width: 100%;
    padding: 10px;
    margin-top: 15px;
    border-radius: 10px;
    border: 1px solid rgb(26, 56, 96, 0.1);
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
}

.ProjectDetails .content .details .mainContainer .toggleProjectElements {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    border-radius: 10px;
    cursor: pointer;
    background-color: rgb(26, 94, 155, 0.08);
    font-weight: 600;
    font-size: 16px;
    color: #1A5E9B;
}

.ProjectDetails .content .details .mainContainer .toggleProjectElements:hover {
    background-color: rgb(26, 94, 155, 0.15);
}

.ProjectDetails .content .details .mainContainer .analyzeProject {
    width: 100%;
    padding-top: 40px;
    display: flex;
    justify-content: center;
}

.ProjectDetails .content .details .mainContainer .analyzeProject a {
    text-decoration: none;
    width: 70%;
    display: flex;
    justify-content: center;
}

.ProjectDetails .content .details .mainContainer .analyzeProject button {
    background-color: rgb(26, 94, 155, 0.9);
    font-weight: 600;
    font-size: 16px;
    width: 100%;
}

.ProjectDetails .content .details .mainContainer .analyzeProject button:hover {
    background-color: rgb(26, 94, 155, 1);
}

.ProjectDetails .content .details .mainContainer .projectComments {
    width: 100%;
    margin-top: 40px;
}

.ProjectDetails .content .details .mainContainer .references {
    margin-top: 40px;
}

.ProjectDetails .content .details .mainContainer .references .referencesHeading {
    display: flex;
    align-items: center;
    font-weight: 600;
    margin-bottom: 10px;
}

.ProjectDetails .content .details .mainContainer .references .referencesHeading .referenceHeadingText {
    margin: 0px 10px;
    font-size: 20px;
}

.ProjectDetails .content .details .mainContainer .references .referencesHeading .referenceCount {
    color: #1A5E9B;
    background-color: rgb(26, 94, 155, 0.1);
    font-size: 14px;
    padding: 2px 7.5px;
    border-radius: 7.5px;
    border: 1px solid rgb(27, 43, 65, 0.13);
}