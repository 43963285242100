.AnnotateFeatureButtons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* padding: 5px 10px; */
    margin: 0px;
    width: 100%;
    height: 100%;
    /* background-color: purple; */
    /* overflow-x: visible; */

}

.AnnotateFeatureButtons .annotateFeatureButtonsContainer {
    height: 100%;
    width: 100%;
    /* background-color: pink; */
    text-align: center;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.AnnotateFeatureButtons .annotateFeatureButtonsContainer .AnnotateFeatureButtonsContent {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    flex-wrap: wrap;
    overflow-y: visible;
}

.AnnotateFeatureButtons .annotateFeatureButtonsContainer .AnnotateFeatureButtonsContent.notAllowed {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    overflow: auto;
    /* padding: 0px 5px; */
}