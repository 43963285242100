.HomeProjects {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
}

.HomeProjects .noProjects {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #192434;
}

.HomeProjects .noProjects .imageContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.HomeProjects .noProjects .imageContainer img {
    width: 500px;
    /* opacity: 0.9; */
}

.HomeProjects .noProjects .imageContainer .imageOverlay {
    position: absolute;
    width: 500px;
    height: 180px;
    background: radial-gradient(rgb(246, 247, 249, 0.1), rgb(246, 247, 249, 0.6));
}

.HomeProjects .noProjects .newProject {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px 10px;

}

.HomeProjects .noProjects .newProject .header {
    font-size: 20px;
    font-weight: 700;
}

.HomeProjects .noProjects button.createProject {
    width: fit-content;
    font-size: 13px;
    background-color: #1A5E9B;
    padding: 5px 10px;
    margin-bottom: 50px;
}

.HomeProjects .noProjects button.createProject span {
    font-weight: 500;
}

.HomeProjects .noProjects button.createProject:hover {
    border: 1px solid #1A5E9B;
}

.HomeProjects .noProjects button.createProject:active {
    background-color: #104B81;
}

.HomeProjects .projects {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 10px 10px;
}

.HomeProjects .projects .title {
    padding: 10px;
    text-align: center;
    height: 70px;
}

.HomeProjects .projects .title .header {
    font-size: 20px;
    font-weight: 700;
    margin: 0px;
}

.HomeProjects .projects .projectList {
    overflow: auto;
    /* height: 100%; */
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
}