.MultiTextHighlighter {
    height: 100%;
}

.MultiTextHighlighter .dropdown.highlighterButton {
    max-height: 100%;
    background-color: #FFFFFF;
    border: 1px solid #EEEEEE;
    border-radius: 7.5px;
    overflow: hidden;
    padding: 5px 5px;
}

.MultiTextHighlighter .dropdown.highlighterButton:hover {
    background-color: #F6F7F9;
}

.MultiTextHighlighter .dropdown.highlighterButton.show {
    border: 1px solid #1A5E9B;
}

.MultiTextHighlighter .dropdown.highlighterButton::after { 
    top: 30%;
    left: 0%;
    transform: translateX(-75%);
} 

.MultiTextHighlighter .multiTextHighlighterDropDown .dropdown-menu {
    width: 25vw;
    /* max-height: 30vh; */
    padding: 0px;
    background-color: #FFFFFF;
    overflow-y: auto;
    border: 1px solid rgba(218, 218, 218, 0.65);
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(25, 59, 103, 0.3);
}

.MultiTextHighlighter .multiTextHighlighterDropDown .dropdown-menu input {
    font-size: 13px;
    padding: 5px;
}

@media screen and (max-width: 950px) {
    .MultiTextHighlighter .multiTextHighlighterDropDown .dropdown-menu {
        width: 40vw;
    }
}

.MultiTextHighlighter .multiTextHighlighterDropDown .dropdown-menu .formHeader {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 20px;
    background-color: rgba(25, 59, 103, 0.05);
    border-bottom: 1.5px solid rgba(218, 218, 218, 0.65);
    background: linear-gradient(-315deg,rgb(106, 128, 148, 0.15),rgb(206, 87, 173, 0.15),rgb(255, 124, 69, 0.15), rgb(205, 210, 65, 0.15), rgb(130, 228, 181, 0.15));
}

.MultiTextHighlighter .multiTextHighlighterDropDown .dropdown-menu .formHeader .imageContainer {
    background-color: white;
    padding: 5px;
    border-radius: 7.5px;
    margin-right: 10px;
}

.MultiTextHighlighter .multiTextHighlighterDropDown .dropdown-menu .formHeader .textContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    margin-right: 10px;
}

.MultiTextHighlighter .multiTextHighlighterDropDown .dropdown-menu .formHeader h5 {
    font-weight: 600;
    margin: 0;
    color: rgb(25, 39, 57, 0.9);
}

.MultiTextHighlighter .multiTextHighlighterDropDown .dropdown-menu .formHeader p {
    font-size: 14px;
    margin: 0;
    color: rgb(25, 39, 57, 0.7);
}

.MultiTextHighlighter .multiTextHighlighterDropDown .dropdown-menu .formContent {
    max-height: 55vh;
    overflow-y: auto;
    padding: 10px 20px;
    /* border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px; */
}

.MultiTextHighlighter .multiTextHighlighterDropDown .dropdown-menu .contentItem label {
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: blue; */
    margin-right: 0px;
    background-color: rgba(25, 59, 103, 0.05);
    border: 1px solid rgba(218, 218, 218, 0.65);
    border-radius: 7.5px 0px 0px 7.5px;
}

.MultiTextHighlighter .multiTextHighlighterDropDown .dropdown-menu .contentItem input {
    width: 100%;
    border-radius: 0px 7.5px 7.5px 0px;
    border: 1px solid rgba(218, 218, 218, 0.65);
    border-left: 0px;
    /* background-color: red; */
}

.MultiTextHighlighter .multiTextHighlighterDropDown .dropdown-menu .highlightColor {
    /* border: 1px solid black; */
    height: 15px;
    width: 15px;
    border-radius: 50%;
}

.MultiTextHighlighter .multiTextHighlighterDropDown .dropdown-menu .buttonContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(25, 59, 103, 0.05);
    padding: 10px 20px;
}

.MultiTextHighlighter .multiTextHighlighterDropDown .dropdown-menu .formClearButton {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.MultiTextHighlighter .multiTextHighlighterDropDown .dropdown-menu .formClearButton input {
    border-radius: 7.5px;
    border: none;
    background-color: rgb(255, 255, 255, 0);
    color: rgb(25, 39, 57, 0.9);
    font-weight: 500;
    text-align: left;
    padding: 0;
    padding-left: 10px;
    margin: 0;
    font-size: 13px;
}

.MultiTextHighlighter .multiTextHighlighterDropDown .dropdown-menu .formSubmitButton {
    padding-left: 20px;
    width: 50%;
}

.MultiTextHighlighter .multiTextHighlighterDropDown .dropdown-menu .formSubmitButton button {
    /* width: 70%; */
    background-color: #1A5E9B;
    color: #FFFFFF;
    font-weight: 500;
    font-size: 13px;
}