.ProjectAnalysisUnfiltered {
    background-color: #FFFFFF;
    border-radius: 10px;
    padding: 15px;
    border: 1px solid rgba(26, 56, 96, 0.1);
}

.ProjectAnalysisUnfiltered .title {
    font-size: 20px;
    font-weight: 600;
    color: #1A5E9B;
    margin-top: 15px;
}


.ProjectAnalysisUnfiltered .tableContainer {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: auto;
}
.ProjectAnalysisUnfiltered .tableContainer table {
    width: 100%;
    font-size: 14px;
    border-radius: 10px;
    border: 1px solid rgb(232, 235, 239, 0.9);
    border-spacing: 0;
    border-collapse: separate;
    overflow: hidden;
    vertical-align: middle;
}

.ProjectAnalysisUnfiltered .tableContainer .table th:not(:last-child),
.ProjectAnalysisUnfiltered .tableContainer .table td:not(:last-child) {
    border-right: 1px solid rgb(232, 235, 239, 0.9);
}

.ProjectAnalysisUnfiltered .tableContainer .table td {
    border: none;
}

.ProjectAnalysisUnfiltered .tableContainer .table>thead>tr:not(:last-child)>th,
.ProjectAnalysisUnfiltered .tableContainer .table>thead>tr:not(:last-child)>td,
.ProjectAnalysisUnfiltered .tableContainer .table>tbody>tr:not(:last-child)>th,
.ProjectAnalysisUnfiltered .tableContainer .table>tbody>tr:not(:last-child)>td,
.ProjectAnalysisUnfiltered .tableContainer .table>tfoot>tr:not(:last-child)>th,
.ProjectAnalysisUnfiltered .tableContainer .table>tfoot>tr:not(:last-child)>td,
.ProjectAnalysisUnfiltered .tableContainer .table>tr:not(:last-child)>td,
.ProjectAnalysisUnfiltered .tableContainer .table>tr:not(:last-child)>th,
.ProjectAnalysisUnfiltered .tableContainer .table>thead:not(:last-child),
.ProjectAnalysisUnfiltered .tableContainer .table>tbody:not(:last-child),
.ProjectAnalysisUnfiltered .tableContainer .table>tfoot:not(:last-child) {
    border-bottom: 1px solid rgb(232, 235, 239, 0.7);
}

.ProjectAnalysisUnfiltered .tableContainer .table thead {
    color: #FFFFFF;
    font-size: 16px;
    text-align: center;
    background-color: rgb(25, 36, 52, 0.9);
    position: -webkit-sticky;
    position: sticky;
    top: -2px;
}

.ProjectAnalysisUnfiltered .tableContainer .table thead .reference {
    text-align: left;
}

.ProjectAnalysisUnfiltered .tableContainer .table thead .element {
    white-space: nowrap;
}

.ProjectAnalysisUnfiltered .tableContainer .table .tableBody {
    overflow: auto;
}

.ProjectAnalysisUnfiltered .tableContainer .table tbody .reference {
    white-space: nowrap;
    display: flex;
    /* justify-content: center; */
    /* width: fit-content; */
}

.ProjectAnalysisUnfiltered .tableContainer .table tbody .reference .referenceContainer {
    background-color: #F8F9FA;
    padding: 5px 7.5px;
    border-radius: 5px;
    box-shadow: 0 0 5px rgb(26, 94, 155, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
}


.ProjectAnalysisUnfiltered .tableContainer .table .reference .referenceContainer span.multiRef {
    color: #FFFFFF;
    background-color: #FF7C45;
    padding: 2px 7.5px;
    border-radius: 5px;
    margin-right: 5px;
}

.ProjectAnalysisUnfiltered .tableContainer .table .reference .referenceContainer span.joiner {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #EDF2F7;
    color: #1A5E9B;
    border: 2px solid #FFFFFF;
    box-shadow: 0 0 17.5px rgb(26, 94, 155, 0.5);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin: 0px 10px;
}

.ProjectAnalysisUnfiltered .tableContainer td.element {
    padding: 5px;
    width: 50px;
    height: 50px;
    white-space: nowrap;
    text-align: center;
}

.ProjectAnalysisUnfiltered .tableContainer td.element .projectAnalysisElementAbsent {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background-color: rgb(21, 132, 68, 0.7);
}

.ProjectAnalysisUnfiltered .tableContainer td.element .projectAnalysisElementAbsent span {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 2px solid #FFFFFF;
    background-color: rgb(21, 132, 68, 1);
}

.ProjectAnalysisUnfiltered .tableContainer td.element .projectAnalysisElementPresent {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background-color: rgb(226, 29, 18, 0.7);
}

.ProjectAnalysisUnfiltered .tableContainer td.element .projectAnalysisElementPresent span {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 2px solid #FFFFFF;
    background-color: rgb(226, 29, 18, 1);
}

.ProjectAnalysisUnfiltered .tableContainer .commentText {
   font-size: 13px;
    text-align: left;
    padding-left: 20px;
    white-space: nowrap;
    overflow: auto;
}

/* .ProjectAnalysisUnfiltered {
    background-color: rgba(128, 128, 128, 0.2);
    padding: 10px;
    margin-bottom: 20px;
}

.ProjectAnalysisUnfiltered .title {
    text-decoration: underline;
    text-align: center;
}

.ProjectAnalysisUnfiltered .tableContainer {
    width: 70%;
    max-height: 60vh;
    margin: 0 auto;
    overflow-y: auto;
} 

.ProjectAnalysisUnfiltered table {
    width: 100%;
    max-height: 10vh;
    text-align: center;
    background-color: white;
    border: 1px solid grey;
}

.ProjectAnalysisUnfiltered table thead {
    background-color: rgba(128, 128, 128, 1);
    position: -webkit-sticky;
    position: sticky;
    top: -2px;
    font-size: 18px;
}

.ProjectAnalysisUnfiltered .serialNumber {
    width: 20px;
    overflow-x: auto;
    vertical-align: middle;
}

.ProjectAnalysisUnfiltered .documentNumbers {
    max-width: 30vw;
    margin: 0px 5px 0px 5px;
}

.ProjectAnalysisUnfiltered .documentNumber {
    white-space: nowrap;
    margin: 0px;
    padding: 0px;
    overflow: auto;
    font-size: 13px;
}

.ProjectAnalysisUnfiltered .element {
    width: 60px;
    overflow-x: hidden;
    font-weight: 700;
    font-size: 20px;
    vertical-align: middle;
}

.ProjectAnalysisUnfiltered .commentText {
    width: 15vw;
    text-align: left;
    padding-left: 20px;
} */