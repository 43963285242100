.ProjectSearch {
    width: 100%;
    height: 100%;
    overflow-y: visible;
    display: flex;
    flex-direction: column;
}

.ProjectSearch .projectSearchContainer {
    background-color: rgba(26, 56, 96, 0.08);
    padding: 10px;
    border-radius: 7.5px;
    /* background-color: aquamarine; */
}

.ProjectSearch .projectSearchContainer .projectSearchDropdown button {
    margin-top: 2px;
    margin-bottom: 10px;
    text-align: center;
    color: #192434;
    background-color: #FFFFFF;
    justify-content: space-between;
    font-size: 14px;
    word-wrap:break-word;
}

.ProjectSearch .projectSearchContainer .projectSearchDropdown button:hover {
    cursor: pointer;
    background-color: #F6F7F9;
}

.ProjectSearch .projectSearchContainer .projectSearchDropdown button:active {
    background-color: #E1E1E1;
}

.ProjectSearch .projectSearchContainer .projectSearchDropdown button.tooltipContainer .tooltiptext {
    width: 120px;
}

.ProjectSearch .projectSearchContainer .projectSearchDropdown button.tooltipContainer .tooltiptext::after {
    left: 55%;
    top: -50%;
}

@media screen and (max-width: 768px) {
    .ProjectSearch .projectSearchContainer .projectSearchDropdown button {
        flex-direction: column;
        justify-content: center;
        font-size: 11px;
    }
    
}

.ProjectSearch .projectSearchContainer .dropdown-menu.projectSearchDropdown {
    padding: 0px;
    width: 25vw;
    max-height: 50vh;
    min-height: 5vh;
    overflow-y: auto;
    text-align: center;
    border-radius: 3px;
    font-size: 13px;
    box-shadow: 0px 0px 5px 5px #F6F7F9;
}

.ProjectSearch .projectSearchContainer .projectNotification .projectNotificationContainer {
    color: #192434;
    background-color: #FFFFFF;
    border: 1px solid #1A5E9B;
    border-radius: 7.5px;
    padding: 5px;
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    font-weight: 500;
}

.ProjectSearch .projectSearchContainer .projectNotification .projectNotificationContainer .title {
    max-height: 80px;
    overflow-y: auto;
}

.ProjectSearch .projectSearchContainer .projectNotification .projectNotificationContainer .refresh {
    height: fit-content;
}

.ProjectSearch .projectSearchContainer .projectNotification .projectNotificationContainer .refresh.tooltipContainer .tooltiptext {
    width: 100px;
    left: -200%;
    top: 125%;
}

.ProjectSearch .projectSearchContainer .projectNotification .projectNotificationContainer .refresh.tooltipContainer .tooltiptext::after {
    left: 50%;
}

.ProjectSearch .projectSearchContainer .projectNotification .projectNotificationContainer .refresh svg {
    cursor: pointer;
}

.ProjectSearch .projectSearchContainer .projectNotification .projectNotificationContainer .refresh svg:hover {
    border: 1px solid #1A5E9B;
    border-radius: 50%;
}

.ProjectSearch .projectInfo {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    overflow-y: auto;
    /* background-color: blueviolet; */
}

.ProjectSearch .projectInfo .projectDocuments {
    height: 100%;
    overflow-y: auto;
    border: 1px solid rgb(26, 56, 96, 0.1);
    border-radius: 7.5px 7.5px 0px 0px;
    display: flex;
    flex-direction: column;
    background-color: rgb(25, 59, 103, 0.03);
    /* background-color: red; */
}

.ProjectSearch .projectInfo .projectDocuments .projectDocumentContainer {
    height: 100%;
    overflow-y: auto;
    background-color: rgb(28, 55, 90, 0.03);
    /* padding-top: 10px; */
}

.ProjectSearch .projectInfo .projectDocuments .projectTitle {
    background-color: #192434;
    color: #FFFFFF;
    padding: 5px 10px;
    margin: 0px;
    font-weight: 500;
    cursor: pointer;
    font-size: 14px;
    width: 100%;
}

.ProjectSearch .projectInfo .projectDocuments .projectTitle span.projTitle:hover {
    text-decoration: underline;
}

.ProjectSearch .dropdown-menu.projectSearchDropdown > li {
    border-bottom: 1px solid rgb(221,221,221);
}

.ProjectSearch .dropdown-menu.projectSearchDropdown > li:hover {
    background-color: rgba(26, 94, 155, 0.05);
    color: #1A5E9B;
}

/* .ProjectSearch .dropdown-menu.projectSearchDropdown > li:nth-of-type(odd) {
    background-color: rgb(241, 241, 252);
}

.ProjectSearch .dropdown-menu.projectSearchDropdown > li:nth-of-type(odd):hover {
    background-color: rgb(229, 229, 244);
} */

.ProjectSearch .dropdown-menu.projectSearchDropdown > li:last-child {
    border-bottom: none;
}

.ProjectSearch .projectSearchDropdown button span::after { 
    top: 100%; 
    left: 50%;
    transform: translateX(-50%);
} 

.ProjectSearch .dropdown.projectDetailsDropdownText {
    width: 100%;
    word-wrap: break-word;
    padding: 0px 2px 0px 2px;
    margin: 10px 0px 5px 15px;
    text-align: left;
    
}

.ProjectSearch .dropdown-menu.projectDetailsDropdown {
    position: relative;
    top: -20px;
    max-width: 70vw;
    max-height: 50vh;
    min-height: 35vh;
    overflow: auto;
    border: 1.5px solid rgb(26, 94, 155, 0.2);
    box-shadow: 0px 3px 10px rgb(26, 56, 96, 0.2);
    border-radius: 10px;
    padding: 10px;
    background-color: #FFFFFF;
}

.ProjectSearch .dropdown-menu.projectDetailsDropdown .projectDocumentHeader {
    text-align: center;
    font-weight: 600;
    font-size: 18px;
}

.ProjectSearch .dropdown-menu.projectDetailsDropdown .projectDocumentHeader a {
    /* font-size: 16px; */
    color: #1A5E9B;
    text-decoration: none;
    margin: 0px;
}

.ProjectSearch .dropdown-menu.projectDetailsDropdown .projectDocumentHeader a:hover {
    text-decoration: underline;
}

.ProjectSearch .dropdown-menu.projectDetailsDropdown .featuresHeading {
    font-weight: 700;
    font-size: 18px;
}