.ProjectStatusActions {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* background-color: greenyellow; */
    white-space: nowrap;
    flex-wrap: wrap;
}

.ProjectStatusActions .formContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 10px;
    border: 1px solid rgb(26, 56, 96, 0.1);
    background-color: #F8F9FA;
    font-weight: 600;
    padding: 5px;
    margin-bottom: 10px;
}

@media screen and (max-width: 960px) {
    .ProjectStatusActions .formContainer {
        width: 100%;
    }
}

.ProjectStatusActions .formContainer form select {
    font-size: 13px;
}

.ProjectStatusActions button.submitForReview {
    background-color: #158444;
    color: #FFFFFF;
    font-weight: 600;
    border-radius: 7.5px;
    /* width: 100%; */
    padding: 7.5px 20px;
}

.ProjectStatusActions button.submittedForReview {
    background-color: #8993A1;
    color: #FFFFFF;
    font-weight: 600;
    border-radius: 7.5px;
    width: 100%;
    padding: 7.5px 20px;
}

.ProjectStatusActions button.generateFinalReport {
    background-color: #158444;
    color: #FFFFFF;
    font-weight: 600;
    border-radius: 7.5px;
    width: 100%;
    padding: 7.5px 20px;
}

.ProjectStatusActions button.generateFinalReport:hover {
    border: 1px solid #158444;
}

.ProjectStatusActions button.generateFinalReport:disabled {
    background-color: #8993A1;
    cursor: not-allowed;
}

.ProjectStatusActions .modal-dialog {
    padding-top: 40px;
    width: 100%;
}

.ProjectStatusActions .modal-content {
    border-radius: 10px;
    border: none;
    width: 100%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
}

.ProjectStatusActions .modal-header {
    /* border: none; */
    padding: 10px;
    text-align: center;
    flex-direction: column;
    border-bottom: 2px solid rgb(26, 56, 96, 0.1);
}

.ProjectStatusActions .modal-header .modal-title {
    font-size: 20px;
    font-weight: 600;
    color: #192434;
    padding-top: 5px;
}

.ProjectStatusActions .modal-header .modalProjTitle {
    font-size: 20px;
    font-weight: 500;
    padding-bottom: 10px;
    color: rgb(27, 43, 65, 0.7);
}

.ProjectStatusActions .modal-body {
    padding: 10px;
    text-align: left;
}

.ProjectStatusActions .modal-body .warningMessage {
    color: rgb(226, 29, 18, 0.8);
    font-weight: 600;
    padding: 10px;
    background-color: rgb(255, 0, 0, 0.07);
    border-radius: 7.5px;
}

.ProjectStatusActions .modal-footer {
    display: flex;
    justify-content: center;
    border: none;
    padding-top: 0px;
    flex-wrap: nowrap;
}

.ProjectStatusActions .modal-footer button {
    width: 100%; 
}

.ProjectStatusActions .modal-footer button.cancel {
    background-color: #FFFFFF;
    color: #192434;
    font-weight: 600;
    border: 1px solid rgb(28, 55, 90, 0.16);
    border-radius: 7.5px;
}

.ProjectStatusActions .modal-footer button.cancel:hover {
    border: 1px solid rgb(28, 55, 90, 0.5);
}

.ProjectStatusActions .modal-footer button.submit {
    background-color: #1A5E9B;
    color: #FFFFFF;
    font-weight: 600;
    border-radius: 7.5px;
}

.ProjectStatusActions .modal-footer button.submit:hover {
    border: 1px solid  #1A5E9B;
}