* {
    box-sizing: border-box;
}

.App {
    background-color: #F6F7F9;
    height: 100vh;
}

.App .body {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}