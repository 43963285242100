.ProjectSearchDocumentFeature {
    margin: 0px 5px 0px 0px;
    padding: 2px 6px;
    font-size: 13px;
    font-weight: 600;
    color: #1A5E9B;
    background-color: rgb(26, 94, 155, 0.12);
    border-radius: 5px;
    /* text-align: center; */
}

.ProjectSearchDocumentFeature.highlightDocumentNumber {
    background-color: #FFFFFF;
}