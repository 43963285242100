.PatentSearchForm {
    width: 50%;
    height: 80%;
    display: flex;
    align-items: center;
    /* background-color: red; */
    /* padding: 5px; */
    /* margin: 3px; */
    /* background-color: #DADADA; */
}

.PatentSearchForm .searchForm {
    width: 100%;
    height: 100%;
    display: flex;
    text-align: center;
    justify-content: space-between;
    border-radius: 7.5px;
    padding: 2.5px 5px;
    background-color: rgba(26, 56, 96, 0.08);
}


.PatentSearchForm .searchForm input {
    border-radius: 5px;
    background-color: rgba(26, 56, 96, 0.0);
    border: none;
}

.PatentSearchForm .searchForm input::placeholder {
    color: rgba(27, 43, 65, 0.69);
    font-size: 13px;
}

.PatentSearchForm .searchForm button {
    width: 80px;
    color: #262626;
    font-weight: 600;
    font-size: 14px;
    background-color: #FFFFFF;
    overflow: hidden;
    padding: 2px 5px;
}