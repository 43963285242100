.ProjectFeaturesItem {
    margin: 5px 0px;
    font-size: 12px;
    background-color: #F2F7FF;
    padding: 10px;
    border-radius: 7.5px;
    overflow: auto;
}

.ProjectFeaturesItem .featureNumber {
    font-size: 14px;
    font-weight: 700;
    display: flex;
    align-items: center;
    background-color: #FFFFFF;
    border-radius: 20px;
    padding: 5px 7.5px;
    width:fit-content;
}

.ProjectFeaturesItem .featureNumber :first-child {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    padding: 10px;
    color: #1A5E9B;
    background-color: #DDE9FA;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.ProjectFeaturesItem .featureNumber :last-child {
    font-weight: 600;
    color: #192434;
}

.ProjectFeaturesItem .featureText {
    color: rgb(27, 43, 65, 0.69);
}